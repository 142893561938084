import { ChatOpenAI } from '@langchain/openai';
import { PromptTemplate } from '@langchain/core/prompts';
import { HttpResponseOutputParser } from 'langchain/output_parsers';

// import { JSONLoader } from "langchain/document_loaders/fs/json";
import { RunnablePassthrough, RunnableSequence } from '@langchain/core/runnables'
import { formatDocumentsAsString } from 'langchain/util/document';
import { CharacterTextSplitter } from 'langchain/text_splitter';
import { ChatPromptTemplate } from "@langchain/core/prompts";
import { StringOutputParser } from "@langchain/core/output_parsers";
import type { Document } from "@langchain/core/documents";
import {OpenAIEmbeddings} from "@langchain/openai";
import { SelfQueryRetriever } from "langchain/retrievers/self_query";
import { PineconeTranslator } from "@langchain/pinecone";
import { attributeInfo } from "../models/chat/chat-attributes";
import {Pinecone} from "@pinecone-database/pinecone";
import { PineconeStore } from "@langchain/pinecone";
import store from "../store/store";
import FirebaseUsage from "../firebase/firebase.usage";
import { FunctionalTranslator } from "langchain/retrievers/self_query/functional";



// const loader = new JSONLoader(
//     "src/data/states.json",
//     ["/state", "/code", "/nickname", "/website", "/admission_date", "/admission_number", "/capital_city", "/capital_url", "/population", "/population_rank", "/constitution_url", "/twitter_url"],
// );


const generateChatPrompt = (context: string, question: string) => {
    const system = `You are an expert at converting user questions into database queries.
You have access to a database of construction project tasks and project information.
Given a question, return a list of database queries optimized to retrieve the most relevant results

If there are acronyms or words you are not familiar with, do not try to rephrase them.`;

    const llm = new ChatOpenAI({
        apiKey: 'sk-hyp8zAJHYaqpaSMbsDzLkucC2TxkGEvYTKd2p0mvxyT3BlbkFJb-3M9oJucn44tuN0Uc-D8JlE0ykOrncYSuyCBOXVUA',
        model: "gpt-4o",
        temperature: 0
    });

    let examples: any = []

    let q = 'When is the project due to finish?'
    let query: any = {
        query: 'Of all the task records which task has the latest early_finish_date attribute',
        subQueries: []
    }
    examples.push({input: q, toolCalls: query})

    q = 'Which is the most important task assigned to John Smith?'
    query = {
        query: 'Of all the task records which task has the highest priority (lowest priority_order number) ' +
            'attribute and is assigned to John Smith (task_force_member attribute includes John Smith or' +
            'or contains the names John and Smith as part of an email address john.smith@example.com)',
        subQueries: ['which task has the highest priority (lowest priority_order number) attribute',
            'which tasks have a task_force_member attribute that includes John Smith or contains the names John and Smith as part of an email address']
    }
    examples.push({input: q, toolCalls: query})


    const prompt = ChatPromptTemplate.fromMessages([
        ["system", system],
        ["placeholder", "{examples}"],
        ["human", "{question}"],
    ]);


}

export const dynamic = 'force-dynamic'

/**
 * Basic memory formatter that stringifies and passes
 * message history directly into the model.
 */
const formatMessage = (message) => {
    return `${message.role}: ${message.content}`;
};

const TEMPLATE =
    `Answer the user's questions based only on the following context. If the answer is not in the context, reply politely that you do not have that information available. 
    The information contained in the context relates to a live construction project. There are a series of collections that contain information about the project. 
    tasks is a list of all the tasks to be completed. project contains information about the project. All dates are in timestamp format. Convert these to human-readable dates (dd-mmm-yyyy).
    ==============================
    Context: {context}
    ==============================
    Current conversation: {chat_history}
    
    user: {question}
    assistant:`;

const TEMPLATE2 =
    `This application is for an AI chatbot. Langchain is being used to return context to the OpenAI chat assistant.
    You need to reformat the user's question so it can be used as a query to the Langchain and Pinecone models.
    The response to this llm query will be used as the input for the langchain selfQueryRetriever.
    The context provided for this task is a JSON string representing the metadata used in the Pinecone database.
    ==============================
    Context: {context}
    ==============================
    Current conversation: {chat_history}
    
    user: {question}
    assistant:`;


export async function sendMessage (messages: any) {
    try {
        const formattedPreviousMessages = messages.slice(0, -1).map(formatMessage);
        const projectTasks =
            [...store.getState().task.tasks.confirmedComplete,
            ...store.getState().task.tasks.declaredComplete,
            ...store.getState().task.tasks.inProgress,
            ...store.getState().task.tasks.pending
            ];
        const project = store.getState().project.activeProject
        const projectData = {
            tasks: projectTasks,
            project: project
        }

        const currentMessageContent = messages[messages.length - 1].content;

        // const docs = await loader.load();

        // load a JSON object
        const textSplitter = new CharacterTextSplitter();

        const docs = await textSplitter.createDocuments([JSON.stringify(projectData)]);

        const prompt = PromptTemplate.fromTemplate(TEMPLATE);

        const model = new ChatOpenAI({
            apiKey: process.env.OPENAI_API_KEY,
            model: 'gpt-3.5-turbo',
            temperature: 0,
            streaming: true,
            verbose: true,
        });

        /**
         * Chat models stream message chunks rather than bytes, so this
         * output parser handles serialization and encoding.
         */
        const parser = new HttpResponseOutputParser();

        const chain = RunnableSequence.from([
            {
                question: (input) => input.question,
                chat_history: (input) => input.chat_history,
                context: () => formatDocumentsAsString(docs),
            },
            prompt,
            model,
            parser,
        ]);

        console.log(currentMessageContent)

        // Convert the response into a friendly text-stream
        const stream = await chain.invoke({
            chat_history: formattedPreviousMessages.join('\n'),
            question: currentMessageContent,
        });

        // read the text stream and return an object
        const blob = new Blob([stream], { type: 'text/plain' });
        const reader = new FileReader();
        reader.readAsText(blob);
        return new Promise((resolve, reject) => {
            reader.onload = () => {
                if (typeof reader.result === "string") {
                    const response = reader.result;
                    console.log(response)
                    resolve(response);
                }
            };
            reader.onerror = reject;
        });


    } catch (e: any) {
        return Response.json({ error: e.message }, { status: e.status ?? 500 });
    }
}

export async function sendChatMessageWithData(messages: any) {
    const formattedPreviousMessages = messages.slice(0, -1).map(formatMessage);
    const project = store.getState().project.activeProject
    const currentMessageContent = messages[messages.length - 1].content;

    const prompt = ChatPromptTemplate.fromTemplate(`
Answer the question based only on the context provided.

Context: {context}

Question: {question}`);

    const formatDocs = (docs: Document[]) => {
        return docs.map((doc) => JSON.stringify(doc)).join("\n\n");
    };

    const llm = new ChatOpenAI({
        apiKey: process.env.OPENAI_API_KEY,
        model: "gpt-4o",
        temperature: 0
    });

    const projectId = project!.projectId;

    const pinecone = new Pinecone({ apiKey: process.env.PINECONE_API_KEY! });
    const pineconeIndex = pinecone.Index("flowchat-qa-index").namespace(projectId);
    const embeddings = new OpenAIEmbeddings({
        apiKey: process.env.OPENAI_API_KEY,
    });
    const vectorStore = await PineconeStore.fromExistingIndex(embeddings, {
        pineconeIndex: pineconeIndex,
        namespace: projectId
    })

    const selfQueryRetriever = SelfQueryRetriever.fromLLM({
        llm: llm,
        vectorStore: vectorStore,
        documentContents: "Task records from a construction project management system.",
        attributeInfo: attributeInfo,
        structuredQueryTranslator: new PineconeTranslator(),
    });

    const ragChain = RunnableSequence.from([
        {
            context: selfQueryRetriever.pipe(formatDocs),
            question: new RunnablePassthrough(),
        },
        prompt,
        llm,
        new StringOutputParser(),
    ]);

    // Convert the response into a friendly text-stream
    // const stream = await ragChain.invoke({
    //     chat_history: formattedPreviousMessages.join('\n'),
    //     question: currentMessageContent,
    // });

    // const stream = await ragChain.invoke({
    //     chat_history: formattedPreviousMessages.join('\n'),
    //     question: currentMessageContent,
    // });

    const stream = await ragChain.invoke(currentMessageContent);

    console.log("text returned", stream)

    return stream;

    // // read the text stream and return an object
    // const blob = new Blob([stream], { type: 'text/plain' });
    // const reader = new FileReader();
    // reader.readAsText(blob);
    // return new Promise((resolve, reject) => {
    //     reader.onload = () => {
    //         if (typeof reader.result === "string") {
    //             const response = reader.result;
    //             console.log(response)
    //             resolve(response);
    //         }
    //     };
    //     reader.onerror = reject;
    // });
}

export async function sendMessageWithPinecone(messages: any) {
    const formattedPreviousMessages = messages.slice(0, -1).map(formatMessage);
    const project = store.getState().project.activeProject
    const currentMessageContent = messages[messages.length - 1].content;
    const response = FirebaseUsage.functions(`get-llm-query-v2/?projectId=${project!.projectId}&message=${currentMessageContent}`)
    ({projectId: project!.projectId, message: currentMessageContent}).then((response: any) => {
        console.log(response)
        return response
    })
        .catch((error: any) => console.log("error", error))
    return response
}


export async function getChatResponseMemoryVectorStore(messages: any) {
    const formattedPreviousMessages = messages.slice(0, -1).map(formatMessage);
    const project = store.getState().project.activeProject
    const currentMessageContent = messages[messages.length - 1].content;
    const vectorStore = store.getState().project.vectorStore

    // const TEMPLATE =
    //     `This application is for an AI chatbot. Langchain is being used to return context to the OpenAI chat assistant.
    // You need to reformat the user's question so it can be used as a query to the Langchain MemoryVectorStore.
    // Your response will be used as the input for the Langchain selfQueryRetriever.
    // You don't need to talk like a human, the only output required is a query that will lead to the best matched context results from Langchain MemoryVectorStore.
    // The context provided for this task is a JSON string representing the metadata used in the Langchain MemoryVectorStore.
    // ==============================
    // Context: {context}
    // ==============================
    // user: {question}
    // assistant:`;

    const TEMPLATE =
        "";

    const llm = new ChatOpenAI({
        apiKey: 'sk-hyp8zAJHYaqpaSMbsDzLkucC2TxkGEvYTKd2p0mvxyT3BlbkFJb-3M9oJucn44tuN0Uc-D8JlE0ykOrncYSuyCBOXVUA',
        model: "gpt-4o",
        temperature: 0
    });

    async function getInputQuery (message) {
        const textSplitter = new CharacterTextSplitter();
        const docs = await textSplitter.createDocuments([JSON.stringify(attributeInfo)]);
        const prompt = PromptTemplate.fromTemplate(TEMPLATE);

        const parser = new StringOutputParser();
        const chain = RunnableSequence.from([
            {
                question: (input) => input.question,
                context: () => formatDocumentsAsString(docs),
            },
            prompt,
            llm,
            parser,
        ]);

        const response = await chain.invoke({
            question: message
        });
        return response
    }

    const query = await getInputQuery(currentMessageContent);
    const selfQueryRetriever = SelfQueryRetriever.fromLLM({
        llm: llm,
        vectorStore: vectorStore,
        documentContents: "Task records from a construction project management system.",
        attributeInfo: attributeInfo,
        structuredQueryTranslator: new FunctionalTranslator(),
        searchParams: {
            k: 20,
        }
    });


    const queryData = await selfQueryRetriever.invoke(`query: ${query}`);

    // const queryData = await selfQueryRetriever.invoke("Which task finishes last?");

    console.log(query, queryData)

    const textSplitter = new CharacterTextSplitter();

    const docs = await textSplitter.createDocuments([JSON.stringify(queryData)]);

    const prompt = ChatPromptTemplate.fromTemplate(`
Answer the question based only on the context provided. 
You are an AI assistant (called FlowBot) reading and providing construction project schedule data to users. 
The context will include records of tasks, projects, or project members associated with a specific construction project.
If the context cannot answer the question, simply inform them that you do not have sufficient information to answer their question.
In most user queries task records will be referred to by task_code or task_name.
When simply "start" or "finish" are referred to, unless otherwise specified, default to early_start_date and early_finish_date respectively.
All dates are given in timestamp or a string denoting that they are not applicable.
Convert all dates to a string in the format dd-mmm-yyyy. 1 = Jan and 12 = Dec. Be sure to avoid jumping a month forward.
Check your working to ensure conversion is correct.
Do not show your working to the user, they only want the information requested.
All durations are given in days.
==============================
Context: {context}
==============================
Question: {question}`);

    const ragChain = RunnableSequence.from([
        {
            context: () => formatDocumentsAsString(docs),
            question: new RunnablePassthrough(),
        },
        prompt,
        llm,
        new StringOutputParser(),
    ]);

    return await ragChain.invoke(currentMessageContent)

}

export async function getChatResponsePineconeVectorStore(messages: any) {
    const formattedPreviousMessages = messages.slice(0, -1).map(formatMessage);
    const project = store.getState().project.activeProject
    const projectId = project!.projectId;
    const currentMessageContent = messages[messages.length - 1].content;
    const pinecone = new Pinecone({ apiKey: process.env.PINECONE_API_KEY || '73b8377a-4673-4e91-871e-f917ff0d712e' });
    const pineconeIndex = pinecone.Index("flowchat-qa-index").namespace(projectId);
    const embeddings = new OpenAIEmbeddings({
        apiKey: process.env.OPENAI_API_KEY || 'sk-hyp8zAJHYaqpaSMbsDzLkucC2TxkGEvYTKd2p0mvxyT3BlbkFJb-3M9oJucn44tuN0Uc-D8JlE0ykOrncYSuyCBOXVUA',
    });
    const vectorStore = await PineconeStore.fromExistingIndex(embeddings, {
        pineconeIndex: pineconeIndex,
        namespace: projectId
    })


    const TEMPLATE =
        `This application is for an AI chatbot. Langchain is being used to return context to the OpenAI chat assistant.
    You need to reformat the user's question so it can be used as a query to the Pincone Vectorstore.
    Your response will be used as the input for the Langchain Pinecone selfQueryRetriever. 
    You don't need to talk like a human, the only output required needs to be in the form of a query that will lead to the best matched context results from the Pinecone Vectorstore.
    The context provided for this task is a JSON string representing the metadata used in the Pinecone Vectorstore. 
    You must use the context to generate the SelfQueryRetriever query.
    ==============================
    Context: {context}
    ==============================
    user: {question}
    assistant:`;

    const llm = new ChatOpenAI({
        apiKey: 'sk-hyp8zAJHYaqpaSMbsDzLkucC2TxkGEvYTKd2p0mvxyT3BlbkFJb-3M9oJucn44tuN0Uc-D8JlE0ykOrncYSuyCBOXVUA',
        model: "gpt-4o",
        temperature: 0
    });

    async function getInputQuery (message) {
        const textSplitter = new CharacterTextSplitter();
        const docs = await textSplitter.createDocuments([JSON.stringify(attributeInfo)]);
        const prompt = PromptTemplate.fromTemplate(TEMPLATE);

        const parser = new StringOutputParser();
        const chain = RunnableSequence.from([
            {
                question: (input) => input.question,
                context: () => formatDocumentsAsString(docs),
            },
            prompt,
            llm,
            parser,
        ]);

        const response = await chain.invoke({
            question: message
        });
        return response
    }

    const query = await getInputQuery(currentMessageContent);
    const selfQueryRetriever = SelfQueryRetriever.fromLLM({
        llm: llm,
        vectorStore: vectorStore,
        documentContents: "Task records from a construction project management system.",
        attributeInfo: attributeInfo,
        structuredQueryTranslator: new PineconeTranslator(),
        searchParams: {
            k: 20,
        }
    });


    const queryData = await selfQueryRetriever.invoke(`query: ${query}`);

    // const queryData = await selfQueryRetriever.invoke("Which task finishes last?");

    console.log(query, queryData)

    const textSplitter = new CharacterTextSplitter();

    const docs = await textSplitter.createDocuments([JSON.stringify(queryData)]);

    const prompt = ChatPromptTemplate.fromTemplate(`
Answer the question based only on the context provided. 
You are an AI assistant (called FlowBot) reading and providing construction project schedule data to users. 
The context will include records of tasks, projects, or project members associated with a specific construction project.
If the context cannot answer the question, simply inform them that you do not have sufficient information to answer their question.
In most user queries task records will be referred to by task_code or task_name.
When simply "start" or "finish" are referred to, unless otherwise specified, default to early_start_date and early_finish_date respectively.
All dates are given in timestamp or a string denoting that they are not applicable.
Convert all dates to a string in the format dd-mmm-yyyy. 1 = Jan and 12 = Dec. Be sure to avoid jumping a month forward.
Check your working to ensure conversion is correct.
Do not show your working to the user, they only want the information requested.
All durations are given in days.
==============================
Context: {context}
==============================
Question: {question}`);

    const ragChain = RunnableSequence.from([
        {
            context: () => formatDocumentsAsString(docs),
            question: new RunnablePassthrough(),
        },
        prompt,
        llm,
        new StringOutputParser(),
    ]);

    return await ragChain.invoke(currentMessageContent)

}