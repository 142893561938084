import React, {useCallback, useEffect, useState} from "react";
import {Login, LoginContainer, Terms,} from "./components/login-page.styled-components";
import {Button, Message, Form} from "semantic-ui-react";
import {useLocation, useNavigate, useParams} from "react-router-dom";
import {useDispatch} from "react-redux";
import Validations from "../../utils/validations";
import AuthorizationThunk from "../../store/thunk/authorization.thunk";
import FirebaseUsage from "../../firebase/firebase.usage";
import {UserDocModel} from "../../models/responses/user.model";
import {COLLECTIONS} from "../../firebase/constants";
import {admins} from "../../utils/admins";
import firebaseConfig from "../../config/firebase.config";
import * as authorizationActions from "../../store/actions/authorization.actions";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faGoogle, faMicrosoft} from "@fortawesome/free-brands-svg-icons";
import {PendingMembershipsModel} from "../../models/pending-memberships.model";
import ProjectModel from "../../models/responses/project.model";
import {MemberOrganizationEnum} from "../../models/member-organization.enum";
import ProjectMembersModel from "../../models/responses/project-members.model";
import {GateKeeperEum} from "../../models/gate-keeper.enum";

const LoginPage = (props: any) => {
  const [openModal, setModalState] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState(false);

  const [confirmTerms, setConfirmTerms] = useState(false);

  const colorPalette = [
      '#e879f9', '#c084fc', '#f472b6', '#38bdf8', '#2dd4bf', '#f87171', '#fb923c', '#a3e635'
      ]

  async function getUserProfilePhoto(result: any) {
      const credential = FirebaseUsage.getCredentialFromResultMS(result)
      const accessToken = credential ? credential.accessToken : null
      let photoUrl: any = null
      if (!accessToken) return null
      try {
          return await fetch(`https://graph.microsoft.com/v1.0/me/photo/$value`, {
              method: "GET",
              headers: {
                  Authorization: `Bearer ${accessToken}`,
                  "Content-Type": "image/jpeg",
              },
          }).then((response) => {
              if (response.ok) {
                  return response.blob();
              }
                throw new Error("Network response was not ok.");
          }).then((blob) => {
              return new Promise((resolve, reject) => {
                  const reader = new FileReader();
                  reader.onload = function () {
                      photoUrl = reader.result;
                      resolve(photoUrl); // Resolve the promise with the photoUrl
                  };
                  reader.onerror = reject; // Reject the promise if there's an error
                  if (blob) reader.readAsDataURL(blob);
              });
          })
      } catch (error) {
          console.error(error)
          return null
      }
  }

  async function getPhotoFromGoogleURL(url: string | null) {
      if (!url) return null;
        try {
            return await fetch(url).then((response) => {
                if (response.ok) {
                    return response.blob();
                }
                throw new Error("Network response was not ok.");
            }).then((blob) => {
                return new Promise((resolve, reject) => {
                    const reader = new FileReader();
                    reader.onload = function () {
                        const photoUrl = reader.result;
                        resolve(photoUrl); // Resolve the promise with the photoUrl
                    };
                    reader.onerror = reject; // Reject the promise if there's an error
                    if (blob) reader.readAsDataURL(blob);
                });
            });
        } catch (error) {
            console.error(error);
            return null;
    }
  }

  // Generic function to log in with a provider
  async function loginWithProvider(photoURL: any, loginResult: any) {
      const user = loginResult.user;
      if (user) {
          let userData: UserDocModel | any;
          const displayName = user.displayName;

          try {
              await FirebaseUsage.getQuery(COLLECTIONS.USERS, ["userEmail", "==", user.email]).
              then((snapshot) => {
                  if (snapshot.docs.length) {
                      userData = snapshot.docs[0] ? snapshot.docs[0].data() as UserDocModel : {userId: null, settings: {notifications: {}},};
                  }
              }) } catch (e) {
              userData = {userId: user.uid, settings: {notifications: {}},};
          }

          if (userData && !userData.userId) {
              userData.userId = user.uid;
          } else if (!userData) {
              userData = {userId: user.uid, settings: {notifications: {}},};
          }

          if (!userData.userColour) {
              userData.userColour = colorPalette[Math.floor(Math.random() * colorPalette.length)]
          }

          const newIsAdmin = user.email ? admins.includes(user.email) : false;
          let firebaseToken: any = null;
          try {
              if (!("Notification" in window)) {
                  alert("This browser does not support desktop notification");
              } else if (Notification.permission === "granted") {
                  firebaseToken = await FirebaseUsage.getTokenFromMessaging({
                      vapidKey: firebaseConfig.vapidKey,
                  });
              } else if (Notification.permission === "default") {
                  Notification.requestPermission().then(() => {
                      if (Notification.permission === "granted") {
                          FirebaseUsage.getTokenFromMessaging({
                              vapidKey: firebaseConfig.vapidKey,
                          })
                              .then((currentToken) => {
                                  if (currentToken) {
                                      firebaseToken = currentToken;
                                  }
                              });
                      }
                  });
              } else {
                  alert("Notifications is not enabled please enable it ");
                  firebaseToken = await FirebaseUsage.getTokenFromMessaging({
                      vapidKey: firebaseConfig.vapidKey,
                  });
              }
          } catch (error) {
              console.log("error", error);
          }

          // Check for pending memberships
          const pendingMemberships = await FirebaseUsage.getQuery(COLLECTIONS.PENDING_MEMBERSHIPS, ["email", "==", user.email])
              .then((snapshot) => snapshot.docs.map((doc) => doc.data() as PendingMembershipsModel));
          for (const membership of pendingMemberships) {
              const project = await FirebaseUsage.getDoc(COLLECTIONS.PROJECTS, membership.projectId)
                  .then((doc) => doc.data() as ProjectModel);
              const projectGrade = project.grade;
              await FirebaseUsage.updateDoc(COLLECTIONS.PROJECTS, membership.projectId, {
                    grade:
                        user.uid === userData.userId ? {
                        ...projectGrade,
                        [user.uid]: membership.grade,
                    } : {
                        ...projectGrade,
                        [user.uid]: membership.grade,
                        [userData.userId]: membership.grade,
                        }
              });

              const projectMemberDoc = FirebaseUsage.getBlankDoc(COLLECTIONS.PROJECT_MEMBERS);
              const memberData: ProjectMembersModel = {
                    is_admin: membership.grade === MemberOrganizationEnum.SUPER_ACTOR,
                    is_ledger: false,
                    is_pending: false,
                    projectId: membership.projectId,
                    grade: membership.grade,
                    gateKeeper: GateKeeperEum.None,
                    userEmail: user.email || "",
                    memberId: projectMemberDoc.id,
                    userId: userData.userId,
                    groupId: "",
                    tasks: 0,
                    lastViewed: FirebaseUsage.timestamp(),
                    totalViews: 0,
                };

              await FirebaseUsage.setDocument(COLLECTIONS.PROJECT_MEMBERS, projectMemberDoc.id, memberData);
              FirebaseUsage.deleteDocument(COLLECTIONS.PENDING_MEMBERSHIPS, membership.pendingId)
                  .catch((error) => console.error(error));

              if (!userData.asMember) {
                  userData.asMember = {};
              }
              userData.asMember[membership.projectId] = {
                grade: membership.grade,
                projectId: membership.projectId,
                memberId: projectMemberDoc.id,
              }
          }

          const userObj: UserDocModel = {
              userEmail: user.email || "",
              authenticated: true,
              isAdmin: newIsAdmin,
              // enableSA: false,
              trial: false,
              isDeleteProjects:false,
              asMember: {},
              ...userData,
              photoURL,
              displayName,
              lastLogin: FirebaseUsage.timestamp(),
              totalVisits: userData.totalVisits ? userData.totalVisits += 1 : 1,
              settings: {
                  token: null,
                  firebaseToken: firebaseToken,
                  notifications: {
                      preferences: false,
                      waitingToStart: false,
                      started: false,
                      resumed: false,
                      reforecasted: false,
                      suspended: false,
                      declaredComplete: false,
                      evaluatedComplete: false,
                      addedByOthers: false,
                      ...(userData.settings ? userData.settings.notifications : {}),
                  },
              },
          };
          try {
              userObj.settings.token =
                  await FirebaseUsage.getToken();
          } catch (error) {
              console.error(error);
          }
          await FirebaseUsage.setDocument(COLLECTIONS.USERS, userObj.userId, userObj).then(() => {
            if (!userObj.customerId) {
                console.log('No customer ID found, creating new customer');
                fetch('https://us-central1-flowledgerprod.cloudfunctions.net/create-jira-customer/?userId=' + userObj.userId)
                    .catch((error) => console.error(error));
            }
          })
          dispatch(authorizationActions.Actions.auth(userObj));
      }
  }

  const submit = useCallback(
    (body) => {
      setModalState(true);
      // @ts-ignore
      dispatch(AuthorizationThunk.sendSignInLinkToEmail(body));
    },
    [dispatch]
  );

  return (
    <Login>
      <LoginContainer>
        <img
          src="/images/flowledger_logo_v1.png"
          alt="flowledger logo"
          style={{ width: "50%", margin: "0 auto 20px" }}
        />
        <Form style={{ display: "flex" }}>
          {/*<Form.Field*/}
          {/*  name="email"*/}
          {/*  normalize={ReduxFormMasks.email}*/}
          {/*  placeholder="Enter your organization e-mail"*/}
          {/*  type="email"*/}
          {/*  component={ReduxInput}*/}
          {/*  validate={[Validations.required, Validations.emailInversion]}*/}
          {/*  style={{ width: "410px" }}*/}
          {/*/>*/}
            <input
                type={"email"}
                name={"email"}
                placeholder="Enter your organization e-mail"
                style={{ width: "410px" }}
                onChange={(e) => setEmail(e.target.value)}
                value={email}
                disabled={openModal}
            />
          <button
            className="ui icon secondary button"
            onClick={() => {
                if (Validations.email(email) && email !== "") {
                    submit(email)
                } else {
                    setEmailError(true)
                }
            }}
            disabled={!confirmTerms || openModal}
          >
            <i aria-hidden="true" className="arrow right icon"></i>
          </button>
        </Form>
        <Terms>
          <input
            type="checkbox"
            id="terms"
            checked={confirmTerms}
            onChange={() => setConfirmTerms(!confirmTerms)}
            style={{ cursor: "pointer", marginRight: "10px" }}
          />
          <label htmlFor="terms" style={{ cursor: "pointer" }}>
            I confirm that I have read and accept the &nbsp;
            <a
              href="https://www.flowledger.io/user-terms-of-service"
              style={{ cursor: "pointer" }}
              rel="noopener noreferrer"
              target="_blank"
            >
              User Terms of Service
            </a>
          </label>
        </Terms>
        {openModal && (
          <Message>Please check your e-mail account to authenticate</Message>
        )}
          <Button disabled={!confirmTerms} style={{marginTop: "30px"}}
              onClick={() => FirebaseUsage.signInWithPopupMS().then(async (result) => {
                  try {
                    const photoURL = await getUserProfilePhoto(result)
                      loginWithProvider(photoURL, result).then(() => {
                          navigate(`/`)
                          console.log("logged in")
                      })
                    } catch (error) {
                      console.error(error)
                      loginWithProvider(null, result).then(() => {
                          navigate(`/`)
                          console.log("logged in")
                      })
                  }
          })
          }>
              <span style={{display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center"}}>
                  <FontAwesomeIcon icon={faMicrosoft} size={"2x"} style={{marginRight: "7px"}}/>
                  <p style={{marginLeft: "7px"}}>Sign in with Microsoft</p>
              </span>
          </Button>
          <Button disabled={!confirmTerms} style={{marginTop: "5px"}}
                  onClick={() => FirebaseUsage.signInWithPopupGoogle().then(async (result) => {
                      const photoURL = await getPhotoFromGoogleURL(result.user.photoURL)
                      loginWithProvider(photoURL, result).then(() => {
                          navigate(`/`)
                          console.log("logged in")
                      })
                  })
                  }>
              <span style={{display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center"}}>
                  <FontAwesomeIcon icon={faGoogle} size={"2x"} style={{marginRight: "7px"}}/>
                  <p style={{marginLeft: "7px"}}>Sign in with Google</p>
              </span>
          </Button>
      </LoginContainer>
    </Login>
  );
};

function withRouter<ComponentProps>(Component: React.FunctionComponent<ComponentProps>) {
    function ComponentWithRouterProp(props: ComponentProps) {
        const location = useLocation();
        const navigate = useNavigate();
        const params = useParams();

        return <Component {...props} router={{ location, navigate, params }} />;
    }

    return ComponentWithRouterProp;
}

export default withRouter(LoginPage)
