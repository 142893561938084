import React, {useEffect, useState} from "react";
import TaskModel from "../../../../../models/responses/task.model";
import {CpmTaskModel} from "../../../../../models/responses/cpm-task.model";
import {
    convertDateToIndex,
    convertIndexToSeconds
} from "../../../../../utils/cpm-functions/cpm-app/functions/handleEvent";
import styles from "../GanttChart.module.scss";
import classNames from "classnames";
import {Popup} from "semantic-ui-react";
import FirebaseUsage from "../../../../../firebase/firebase.usage";
import TaskStatusModel from "../../../../../models/responses/task-status.model";

export default function TaskBar(props: {taskData: TaskModel,
                                task: CpmTaskModel,
                                viewWidth: number,
                                viewStart: Date,
                                viewFinish: Date,
                                dataDate: number,
                                isTaskForce: boolean,
                                projectCalendars: any,
                                runEvent: any,
                                setPopUpOpen: any,
                                setPopUpPosition: any,
                                userIsAdmin: boolean}) {
    const {taskData,
        task,
        viewWidth,
        viewStart,
        viewFinish,
        dataDate,
        projectCalendars,
        isTaskForce,
        runEvent,
        setPopUpOpen,
        setPopUpPosition,
        userIsAdmin
    } = props

    const [start, setStart] = useState<number>(convertIndexToSeconds(task.es, task.cal_id, projectCalendars))
    const [finish, setFinish] = useState<number>(convertIndexToSeconds(task.ef, task.cal_id, projectCalendars))
    const [activeDate, setActiveDate] = useState<number>(convertIndexToSeconds(task.ad, task.cal_id, projectCalendars))
    const [resizing, setResizing] = useState<boolean>(false)
    const [moving, setMoving] = useState<boolean>(false)
    const startDate = viewStart.getTime() / 1000
    const finishDate = viewFinish.getTime() / 1000
    const widthPast = ((new Date().getTime() / 1000) - startDate) / (finishDate - startDate) * viewWidth * 0.5
    const blStart = taskData.blActualStartDate ? taskData.blActualStartDate.seconds : taskData.blEarlyStartDate ? taskData.blEarlyStartDate.seconds : null
    const blFinish = taskData.blActEndDate ? taskData.blActEndDate.seconds : taskData.blEarlyEndDate ? taskData.blEarlyEndDate.seconds : null
    const areaListener = new AbortController()

    const handleMouseMove = (e: MouseEvent, type, initialX) => {
        if (type === "resize") {
            const newFinish = Math.max(start, dataDate, finish + (((e.clientX - initialX) / (viewWidth * 0.5)) * (finishDate - startDate)))
            setFinish(newFinish)
            setPopUpPosition({x: e.clientX + 183 > viewWidth ? e.clientX - 196 : e.clientX, y: e.clientY, start: start, finish: newFinish})
            setPopUpOpen(true)
            return
        }
        if (type === "move") {
            const newStart = Math.ceil(Math.max(dataDate, start + (((e.clientX - initialX) / (viewWidth * 0.5)) * (finishDate - startDate))) / 1800) * 1800
            const newFinish = task.duration === 0 ? newStart : convertIndexToSeconds(convertDateToIndex(newStart, task.cal_id, projectCalendars) + task.duration, task.cal_id, projectCalendars)
            if (task.status_code === TaskStatusModel.BLOCK || task.status_code === TaskStatusModel.NOT_STARTED) {
                setStart(newStart)
            }
            setActiveDate(newStart)
            setFinish(newFinish)
            setPopUpPosition({x: e.clientX + 183 > viewWidth ? e.clientX - 196 : e.clientX, y: e.clientY, start: activeDate, finish: newFinish})
            setPopUpOpen(true)
            return
        }
    }

    function addListenerToTask(type: string, initialX: number) {
        document.addEventListener("keydown", (e) => {
            // if escape is pressed
            if (e.key === "Escape") {
                console.log("removing event")
                areaListener.abort()
                setPopUpOpen(false)
                const oldStart = convertIndexToSeconds(task.es, task.cal_id, projectCalendars)
                const oldFinish = convertIndexToSeconds(task.ef, task.cal_id, projectCalendars)
                setStart(oldStart)
                setFinish(oldFinish)
                setResizing(false)
                setMoving(false)
            }
        }, {signal: areaListener.signal})

        document.addEventListener("mouseup", (e) => {
            console.log("mouse up", type, initialX)
            if (type === "resize") {
                const newFinish = convertIndexToSeconds(
                    convertDateToIndex(Math.floor((Math.max(start, dataDate, finish + (((e.clientX - initialX) / (viewWidth * 0.5)) * (finishDate - startDate))) + 86400) / 86400) * 86400,
                        task.cal_id,
                        projectCalendars) - 1, task.cal_id, projectCalendars)
                const remainingDuration = typeof task.es === 'number' ? (convertDateToIndex(newFinish, task.cal_id, projectCalendars) - 1 - task.es) * 1800 : 0
                const currentEf = convertIndexToSeconds(
                    convertDateToIndex(Math.floor((finish + 86400) / 86400) * 86400, task.cal_id, projectCalendars) - 1,
                    task.cal_id,
                    projectCalendars)
                if (currentEf !== newFinish) {
                    runEvent(taskData?.task_id, "duration", FirebaseUsage.timestamp(new Date(newFinish * 1000)), remainingDuration, "none")
                        .catch((e) => console.log(e))
                }
            }
            if (type === "move") {
                const newStart = convertIndexToSeconds(
                    convertDateToIndex(Math.floor((Math.max(dataDate, start + (((e.clientX - initialX) / (viewWidth * 0.5)) * (finishDate - startDate))) + 86400) / 86400) * 86400,
                        task.cal_id,
                        projectCalendars) - 1, task.cal_id, projectCalendars)
                const currentEs = convertIndexToSeconds(
                    convertDateToIndex(Math.floor((start + 86400) / 86400) * 86400, task.cal_id, projectCalendars) - 1,
                    task.cal_id,
                    projectCalendars)
                if (currentEs !== newStart) {
                    runEvent(taskData?.task_id, "constrain", FirebaseUsage.timestamp(new Date(newStart * 1000)))
                        .catch(e => console.error(e))
                }
            }
            areaListener.abort()
            setPopUpOpen(false)
        }, {signal: areaListener.signal})
        document.addEventListener("mousemove", (event) => handleMouseMove(event, type, initialX), {signal: areaListener.signal})
    }

    useEffect(() => {
        setStart(convertIndexToSeconds(task.es, task.cal_id, projectCalendars))
        setFinish(convertIndexToSeconds(task.ef, task.cal_id, projectCalendars))
    }, [task]);

    const futureBarStart = Math.max(start, activeDate)

    const pastBar = start && start < dataDate ? start === finish ? (
            <div
                className={blStart ? styles.TaskBarMilestoneWithBaseline : styles.TaskBarMilestone}
                style={{
                    left: `${(((start - startDate) / (finishDate - startDate)) * viewWidth * 0.5) - 6}px`
                }}
            ></div>) :
            (<div className={finish < dataDate ? styles.TaskBarTotalPast : styles.TaskBarPast}
                 style={{
                     minWidth: `${Math.max(((Math.min(dataDate, finish) - start) / (finishDate - startDate)) * viewWidth * 0.5, 5)}px`,
                     maxWidth: `${Math.max(((Math.min(dataDate, finish) - start) / (finishDate - startDate)) * viewWidth * 0.5, 5)}px`,
                     left: `${((start - startDate) / (finishDate - startDate)) * viewWidth * 0.5}px`
                 }}
            ></div>) : <div style={{minHeight: "14px", maxHeight: "14px"}}></div>

    const futureBar = finish > dataDate ? start === finish ?
        (<div
            className={blStart ? styles.TaskBarMilestoneWithBaseline : styles.TaskBarMilestone}
            onMouseDown={isTaskForce && userIsAdmin ? (e) => {
                addListenerToTask("move", e.clientX)
                setMoving(true)
            } : undefined}
            style={{
                cursor: `${isTaskForce && userIsAdmin ? moving ? "grabbing" : "grab" : "auto"}`,
                left: `${(((Math.max(dataDate, start) - Math.max(dataDate, startDate)) / (finishDate - startDate)) * viewWidth * 0.5) - 6}px`
            }}></div>) :
        (<div
            className={classNames(task.status_code === TaskStatusModel.NOT_STARTED ? styles.TaskBarTotalFuture : styles.TaskBarFuture, {[styles.CriticalPath]: Boolean(taskData.float <= 2)})}
            style={{
                minWidth: `${Math.max(((finish - Math.max(dataDate, futureBarStart, startDate)) / (finishDate - startDate)) * viewWidth * 0.5, 5)}px`,
                maxWidth: `${((finish - Math.max(dataDate, futureBarStart, startDate)) / (finishDate - startDate)) * viewWidth * 0.5}px`,
                left: `${(((Math.max(dataDate, futureBarStart, startDate) - Math.max(dataDate, startDate)) / (finishDate - startDate)) * viewWidth * 0.5)}px`,
                display: "flex",
                flexDirection: "row",
            }}
        >
            {isTaskForce && userIsAdmin && <div style={{minWidth: `${Math.max(((finish - Math.max(dataDate, futureBarStart, startDate)) / (finishDate - startDate)) * viewWidth * 0.5, 5) - 4}px`,
                cursor: `${moving ? "grabbing" : "grab"}`}} onMouseDown={(e) => {
                    addListenerToTask("move", e.clientX)
                    setMoving(true)
            }}></div>}
            {isTaskForce && userIsAdmin && <div style={{minWidth: "4px", cursor: "e-resize"}} onMouseDown={(e) => {
                addListenerToTask("resize", e.clientX)
                setResizing(true)
            }}></div>}
        </div>) : <div style={{minHeight: "14px", maxHeight: "14px"}}></div>


    const blPastBar = blStart && blStart < dataDate ? blStart === blFinish ? (
        <div className={styles.BaselineMilestone}
            style={{
                left: `${(((blStart - startDate) / (finishDate - startDate)) * viewWidth * 0.5) - 4}px`,
                top: "8px"
            }}
        ></div>) : (
            <div
            className={styles.TaskBarBaseline}
            style={{
                minWidth: `${Math.max((((blFinish! - blStart) / (finishDate - startDate)) * viewWidth * 0.5), 5)}px`,
                maxWidth: `${Math.max((((blFinish! - blStart) / (finishDate - startDate)) * viewWidth * 0.5), 5)}px`,
                left: `${(((blStart - startDate) / (finishDate - startDate)) * viewWidth * 0.5)}px`,
                top: "calc(50% - 6px)"
            }}></div>) : null

    const blFutureBar = blFinish && blFinish > dataDate ?
        <Popup trigger={
            blStart === blFinish ?
                <div
                    className={styles.BaselineMilestone}
                    style={{
                        left: `${(((Math.max(dataDate, blStart) - Math.max(dataDate, startDate)) / (finishDate - startDate)) * viewWidth * 0.5) - 4}px`,
                        top: "8px"
                    }}></div> :
                <div className={styles.TaskBarBaseline}
                     style={{
                         minWidth: `${Math.max(((((blFinish!) - Math.max(dataDate, blStart!, startDate)) / (finishDate - startDate)) * viewWidth * 0.5), 5)}px`,
                         maxWidth: `${Math.max(((((blFinish!) - Math.max(dataDate, blStart!, startDate)) / (finishDate - startDate)) * viewWidth * 0.5), 5)}px`,
                        left: `${(((Math.max(dataDate, blStart!, startDate) - Math.max(dataDate, startDate)) / (finishDate - startDate)) * viewWidth * 0.5)}px`,
                        top: "calc(50% - 6px)"
                    }}></div>}
               content={
                    <div>
                          <div>Baseline Start: {new Date(blStart! * 1000).toLocaleDateString()}</div>
                          <div>Baseline Finish: {new Date(blFinish! * 1000).toLocaleDateString()}</div>
                     </div>
               } />
        : null

    return (
        <div className={styles.GanttChartCellContainer} style={{
            minWidth: `${viewWidth * 0.5}px`,
            maxWidth: `${viewWidth * 0.5}px`,
            height: "100%"
        }}>
            {dataDate > startDate &&
                <div className={styles.GanttChartCellPast}
                     style={{
                         minWidth: `${widthPast}px`,
                         maxWidth: `${widthPast}px`,
                     }}>
                    {pastBar}
                    {blPastBar}
                </div>}
            {dataDate < finishDate && <div className={styles.GanttChartCellFuture}
                style={{
                    minWidth: `${((finishDate - dataDate) / (finishDate - startDate)) * viewWidth * 0.5}px`,
                    maxWidth: `${((finishDate - dataDate) / (finishDate - startDate)) * viewWidth * 0.5}px`,
                    left: `${(dataDate / (finishDate - startDate)) * viewWidth * 0.5}px`,
                }}>
                {futureBar}
                {blFutureBar}
            </div>}
        </div>
    )
}