import React, {useCallback, useEffect, useState} from "react";
import {useTypedSelector} from "../../../../../store/selectors/selectors.utils";
import {TaskPredecessors} from "./TaskPredecessors";
import {TaskSuccessors} from "./TaskSuccessors";
import {TaskListSection} from "./TaskListSection/TaskListSection";
import {TaskListSectionModel} from "../../../../../models/task-list-section.model";
import InfiniteScroll from "react-infinite-scroller";
import {useDispatch} from "react-redux";
import * as taskActions from "../../../../../store/actions/task.actions";
import {Loader} from "semantic-ui-react";
import {
    useActiveProjectCalendarsSelector,
    useActiveProjectSelector, useCpmMapSelector,
    useProjectViewSelector
} from "../../../../../store/selectors/project.selectors";
import {
    useIncludeMilestonesSelector,
    useSearchParamatersSelector
} from "../../../../../store/selectors/search.selectors";
import moment from "moment";
import TaskModel from "../../../../../models/responses/task.model";
import GoogleMapVisGL from "../../../components/GoogleMap/GoogleMapVisGL";
import filterTaskList from "../../../../../utils/task-filtering";
import TaskStatusModel from "../../../../../models/responses/task-status.model";
import {
    useAllTaskListSelector,
    useCompleteTaskListSelector,
    useDeclaredCompleteTaskListSelector,
    useInProgressTaskListSelector,
    usePendingTaskListSelector,
    useQueuedTaskListSelector,
    useTaskSnapshotSelector
} from "../../../../../store/selectors/task/task.selectors";
import {subsManager} from "../../../../../store/middleware/subs-manager/subs-manager";
import {TaskType} from "../../../../../models/task-type";
import {convertIndexToSeconds} from "../../../../../utils/cpm-functions/cpm-app/functions/handleEvent";

export const SprintTaskList = () => {
    const [view, setView] = useState<"future" | "past">("future");
    const [sortCriteria, setSortCriteria] = useState<"index" | "deadline" | "target">("index");
    const activeProject = useActiveProjectSelector();
    const dispatch = useDispatch();
    const projectView = useProjectViewSelector();

    let now = new Date();
    now.setMinutes(0, 0, 0);

    const loading = useTypedSelector((state) => state.task.taskListLoading);
    const moreTasks = useTypedSelector((state) => state.task.moreTasks);
    const searchParams = useSearchParamatersSelector();
    const inProgressTaskList = useInProgressTaskListSelector();
    const confimedCompleteTaskList = useCompleteTaskListSelector();
    const pendingTaskList = usePendingTaskListSelector();
    const declaredCompleteTaskList = useDeclaredCompleteTaskListSelector();
    const queuedTaskList = useQueuedTaskListSelector();
    const allTasks = useAllTaskListSelector();
    const includeMilestones = useIncludeMilestonesSelector();

    const [filteredArchivedTaskList, setFilteredArchivedTaskList] = useState<TaskModel[]>([]);
    const [filteredDeclaredTaskList, setFilteredDeclaredTaskList] = useState<TaskModel[]>([]);
    const [filteredInProgressTaskList, setFilteredInProgressTaskList] = useState<TaskModel[]>([]);
    const [archivedOpen, setArchivedOpen] = useState(false);
    const [declaredOpen, setDeclaredOpen] = useState(false);
    const [inProgressOpen, setInProgressOpen] = useState(true);
    const [archivedTaskCount, setArchivedTaskCount] = useState(0);
    const [declaredTaskCount, setDeclaredTaskCount] = useState(0);
    const [inProgressTaskCount, setInProgressTaskCount] = useState(0);
    const [filtersLoaded, setFiltersLoaded] = useState(false);
    const taskSnapshot = useTaskSnapshotSelector();
    const projectCalendars = useActiveProjectCalendarsSelector();
    const cpmMap = useCpmMapSelector();

    useEffect(() => {
        if (taskSnapshot.size === 0) {
            subsManager.subscribeTaskSnapshot(activeProject?.snapshotDataVersionId || "", activeProject?.contractId || "");
        }
    }, [taskSnapshot]);

    useEffect(() => {
        if (taskSnapshot.size > 0) {
            let archivedTaskList: TaskModel[] = [];
            let declaredTaskList: TaskModel[] = [];
            let inProgressTaskList: TaskModel[] = [];
            let archivedTaskCount = 0;
            let declaredTaskCount = 0;
            let inProgressTaskCount = 0;
            filterTaskList(allTasks, searchParams).forEach((task: TaskModel) => {
                const cutOffDate = new Date(activeProject!.lastSnapshotTimestamp!.toDate().getTime() + (7 * 86400000));
                const taskSnapshotData = taskSnapshot.get(task.task_code);

                if (!includeMilestones && task.task_type !== TaskType.TT_RSRC && task.task_type !== TaskType.TT_TASK) return;
                if (taskSnapshotData) {
                    if (taskSnapshotData.forecastStartDate.toDate().getTime() < cutOffDate.getTime() &&
                        !taskSnapshotData.actEndDate) {
                        if (task.status === TaskStatusModel.COMPLETE) {
                            archivedTaskList.push(task);
                            archivedTaskCount++;
                        } else if (task.status === TaskStatusModel.DECLARED_COMPLETE) {
                            declaredTaskList.push(task);
                            declaredTaskCount++;
                        } else if ((task.status === TaskStatusModel.IN_PROGRESS || task.status === TaskStatusModel.SUSPENDED) && task.flow) {
                            inProgressTaskList.push(task);
                            inProgressTaskCount++;
                        } else if (task.flow) {
                            inProgressTaskList.push(task);
                            inProgressTaskCount++;
                        }
                    }
                }
            });
            if (sortCriteria === "index") {
                inProgressTaskList.sort((a, b) => a.index! - b.index!);
            } else if (sortCriteria === "deadline") {
                inProgressTaskList.sort((a, b) => {
                    const aTask = cpmMap.get(a.task_id);
                    const bTask = cpmMap.get(b.task_id);
                    const aLateEndDate = convertIndexToSeconds(aTask.lf, aTask.cal_id, projectCalendars);
                    const bLateEndDate = convertIndexToSeconds(bTask.lf, bTask.cal_id, projectCalendars);
                    return aLateEndDate - bLateEndDate;
                });
            } else if (sortCriteria === "target") {
                inProgressTaskList = inProgressTaskList.sort((a, b) => {
                    if (a.targetFinishDate) {
                        if (b.targetFinishDate) {
                            return a.targetFinishDate.seconds - b.targetFinishDate.seconds;
                        }
                        return -1;
                    }
                    if (b.targetFinishDate) {
                        return 1;
                    }
                    const aTask = cpmMap.get(a.task_id);
                    const bTask = cpmMap.get(b.task_id);
                    const aEarly = convertIndexToSeconds(aTask.ef, aTask.cal_id, projectCalendars);
                    const bEarly = convertIndexToSeconds(bTask.ef, bTask.cal_id, projectCalendars);
                    return aEarly - bEarly;
                });
            }

            setFilteredArchivedTaskList(archivedTaskList);
            setFilteredDeclaredTaskList(declaredTaskList);
            setFilteredInProgressTaskList(inProgressTaskList);
            setArchivedTaskCount(archivedTaskCount);
            setDeclaredTaskCount(declaredTaskCount);
            setInProgressTaskCount(inProgressTaskCount);
            setFiltersLoaded(true);
        }
    }, [searchParams, sortCriteria, taskSnapshot, inProgressTaskList, confimedCompleteTaskList, pendingTaskList, declaredCompleteTaskList, queuedTaskList]);



    const openPredecessors = useTypedSelector(
        (state) => state.task.predecessors.open
    );
    const openSuccessors = useTypedSelector(
        (state) => state.task.successors.open
    );
    const loadMoreHandler = useCallback(
        (page: number) => {
            if (view === "future") {
                if (!loading.QueuedIsLoading && moreTasks.queued) {
                    dispatch(
                        taskActions.Actions.loadMoreTaskList(
                            page,
                            TaskListSectionModel.QUEUED
                        )
                    );
                }
            } else {
                if (
                    !loading.ConfirmedCompleteIsLoading &&
                    moreTasks.confirmedComplete
                ) {
                    dispatch(
                        taskActions.Actions.loadMoreTaskList(
                            page,
                            TaskListSectionModel.CONFIRMED_COMPLETE
                        )
                    );
                }
            }
        },
        [
            dispatch,
            loading.ConfirmedCompleteIsLoading,
            loading.QueuedIsLoading,
            moreTasks.confirmedComplete,
            moreTasks.queued,
            view,
        ]
    );

    if (openPredecessors) {
        return <TaskPredecessors />;
    }

    if (openSuccessors) {
        return <TaskSuccessors />;
    }

    return (
        <div style={{minWidth: "100%"}}>
            <div className="tasks-sort-header"
                 style={{
                     backgroundColor: "rgb(255 255 255 / 80%)",
                     zIndex: "200"
                 }}>
                <div className="priority-task">
                    <div
                        className={"priority " + (sortCriteria === "index" ? "active" : "")}
                        onClick={() => setSortCriteria("index")}
                    >
                        <span className="priority-text">Priority</span>&nbsp;
                    </div>
                    <div className="task-desc">Deliverable</div>
                </div>
                <div className="right-filters">
                    <div
                        className={"time " + (sortCriteria === "target" ? "active" : "")}
                        onClick={() => setSortCriteria("target")}
                    >
                        <div className="time-text">Target</div>
                        &nbsp;
                    </div>
                    <div className={"critical " + (sortCriteria === "deadline" ? "active" : "")}
                         onClick={() => setSortCriteria("deadline")}
                    >Deadline
                    </div>
                </div>
            </div>
            {filtersLoaded ?
            <div className="lazy-load-container">
                {projectView === "task-map" && <GoogleMapVisGL/>}
                <InfiniteScroll
                    pageStart={1}
                    loadMore={loadMoreHandler}
                    initialLoad={false}
                    hasMore={
                        view === "future"
                            ? !loading.QueuedIsLoading && moreTasks.queued
                            : !loading.ConfirmedCompleteIsLoading && moreTasks.confirmedComplete
                    }
                    useWindow={false}
                >
                    <div className="lazy-load-content" style={{zIndex: "100"}}>
                        {filteredArchivedTaskList.length > 0 ? (
                            <TaskListSection
                                text="Archive"
                                moreTasks={moreTasks.confirmedComplete}
                                isLoading={false}
                                headline={"Archive"}
                                title={
                                    "Archive"
                                }
                                taskList={filteredArchivedTaskList}
                                view={view}
                                setView={setView}
                                total={archivedTaskCount + declaredTaskCount + inProgressTaskCount}
                                quality={false}
                                type={"task-sprint"}
                                open={archivedOpen}
                                setOpen={setArchivedOpen}
                            />
                        ) : null}
                        {filteredDeclaredTaskList.length > 0 ? (
                            <TaskListSection
                                text="Ready for Sign-off"
                                moreTasks={moreTasks.confirmedComplete}
                                isLoading={false}
                                headline={"Ready for Sign-off"}
                                title={"Ready for Sign-off"}
                                taskList={filteredDeclaredTaskList}
                                view={view}
                                setView={setView}
                                total={archivedTaskCount + declaredTaskCount + inProgressTaskCount}
                                quality={false}
                                type={"task-sprint"}
                                open={declaredOpen}
                                setOpen={setDeclaredOpen}
                            />
                        ) : null}
                        {filteredInProgressTaskList.length > 0 ? (
                            <TaskListSection
                                text="Work in progress"
                                moreTasks={moreTasks.confirmedComplete}
                                isLoading={false}
                                headline={"Work in progress"}
                                title={"Work in progress"}
                                taskList={filteredInProgressTaskList}
                                view={view}
                                setView={setView}
                                total={archivedTaskCount + declaredTaskCount + inProgressTaskCount}
                                quality={false}
                                type={"task-sprint"}
                                open={inProgressOpen}
                                setOpen={setInProgressOpen}
                            />
                        ) : null}
                        {(view === "future"
                            ? loading.QueuedIsLoading
                            : loading.ConfirmedCompleteIsLoading) && (
                            <div className="load-more-items-wrap" key={0}>
                                <Loader active/>
                            </div>
                        )}
                    </div>
                </InfiniteScroll>
            </div> : <Loader active content={"Loading sprint..."} size={"large"}/>
            }
        </div>
    );
};


