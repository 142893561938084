import React, {useState} from "react";
import {Dropdown, DropdownDivider} from "semantic-ui-react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faHandshake, faHandPointLeft, faPlay, faPause, faCheck, faBan} from "@fortawesome/pro-light-svg-icons";
import TaskModel from "../../../../../models/responses/task.model";
import TaskStatusModel from "../../../../../models/responses/task-status.model";
import {joinTaskForce, leaveTaskForce} from "../../../../../utils/taskforce.utils";
import FirebaseUsage from "../../../../../firebase/firebase.usage";
import EventTextModel from "../../../../../models/event-text-model.enum";

export default function TaskDropdown(props: {
    contextTask: {task: TaskModel, x: number, y: number},
    setContextTask: any,
    userId: string,
    setEventText: any
    setEventTask: any,
    setModalOpened: any,
    setSubMenu: any
}) {
    const {
        contextTask,
        setContextTask,
        userId
    } = props;
    const task = contextTask.task;
    const isTaskForce = task.taskForce?.includes(userId);

  return (
      <Dropdown
          open={contextTask !== null}
          onClose={() => setContextTask(null)}
          icon={null}
          style={{position: "fixed", top: contextTask?.y, left: contextTask?.x}}
      >
          <Dropdown.Menu>
              <Dropdown.Header>Task Options</Dropdown.Header>
              {!isTaskForce && <Dropdown.Item
                  onClick={() => {
                  console.log("Joining taskforce");
                  joinTaskForce(task.task_id, userId, userId, FirebaseUsage.timestamp())
                      .catch((error) => console.error(error));
              }}><FontAwesomeIcon icon={faHandshake} style={{marginRight: "8px"}}/>Join taskforce</Dropdown.Item>}
              {isTaskForce && <Dropdown.Item
                    onClick={() => {
                    console.log("Leaving taskforce");
                    leaveTaskForce(task.task_id, userId, userId, FirebaseUsage.timestamp())
                        .catch((error) => console.error(error));
                }}
              ><FontAwesomeIcon icon={faHandPointLeft} style={{marginRight: "8px"}}/>Leave taskforce</Dropdown.Item>}
              {isTaskForce && <DropdownDivider />}
              {isTaskForce && task.status === TaskStatusModel.NOT_STARTED && <Dropdown.Item
                  onClick={() => {
                        props.setEventTask(task);
                        props.setEventText(EventTextModel.START_TASK);
                        props.setModalOpened(true);
                  }}
              ><FontAwesomeIcon icon={faPlay} style={{marginRight: "8px"}}/>Start task</Dropdown.Item>}
              {isTaskForce && task.status === TaskStatusModel.IN_PROGRESS && <Dropdown.Item
                    onClick={() => {
                            props.setEventTask(task);
                            props.setEventText(EventTextModel.COMPLETE_TASK);
                            props.setModalOpened(true);
                    }}
              ><FontAwesomeIcon icon={faCheck} style={{marginRight: "8px"}}/>Complete task</Dropdown.Item>}
              {isTaskForce && task.status === TaskStatusModel.IN_PROGRESS && <Dropdown.Item
                    onClick={() => {
                        props.setSubMenu({type: "suspend", task: task, x: contextTask.x, y: contextTask.y});
                    }}
              ><FontAwesomeIcon icon={faPause} style={{marginRight: "8px"}}/>Suspend task</Dropdown.Item>}
              {isTaskForce && task.status === TaskStatusModel.NOT_STARTED && <Dropdown.Item
                  onClick={() => {
                        props.setSubMenu({type: "block", task: task, x: contextTask.x, y: contextTask.y});
                  }}
              ><FontAwesomeIcon icon={faBan} style={{marginRight: "8px"}}/>Block task</Dropdown.Item>}

          </Dropdown.Menu>
      </Dropdown>
  );
}