/* eslint react/jsx-handler-names: "off" */
import React, {useEffect, useMemo, useState} from 'react';
import { Zoom } from '@visx/zoom';
import { localPoint } from '@visx/event';
import { RectClipPath } from '@visx/clip-path';
import generateDAGData from "../../../../utils/cpm-functions/set-cpm-map/functions/generateDAGData";
import * as d3Dag from "d3-dag";
import {Group} from "@visx/group";
import {useActiveProjectCalendarsSelector, useCpmMapSelector} from "../../../../store/selectors/project.selectors";
import {LinkHorizontal} from "@visx/shape";
import {
    PopupHeader,
    PopupContent,
    Popup,
} from 'semantic-ui-react'
import {convertIndexToSeconds} from "../../../../utils/cpm-functions/cpm-app/functions/handleEvent";
import {useDispatch} from "react-redux";
import * as taskActions from "../../../../store/actions/task.actions";
import {
    useInProgressTaskListSelector,
    useQueuedTaskListSelector
} from "../../../../store/selectors/task/task.selectors";

const blue = "#4285f4";
const predStatusRed = "#d9001b";
const predStatusGreen = "#0f9d58";
const predStatusYellow = "#f4b400";
const yellow = "#f1e05a";
const lightpurple = "#374469";
const bg = '#CFE7D8';
const redBg = "#F6D1CE";
const background = "white"

const nodeWidth = 150;
const nodeHeight = 60;

export type ZoomIProps = {
    width: number;
    height: number;
    taskId: string | null;
    delay: boolean
    scope: string
    setShowDag: (show: boolean) => void
    setActiveGreenMilestone: (milestone: any) => void
    setActiveRedMilestone: (milestone: any) => void
    driving: boolean
};

function calculateXTranslation(firstNode: any,
                               lastNode: any,
                               nodeWidth: number,
                               masterWidth: number) {
    const startX = firstNode.uy
    const endX = lastNode.uy
    const plotWidth = endX - startX
    if (plotWidth > masterWidth) {
        return -1 * endX + masterWidth - nodeWidth
    }
    return -1 * startX + (masterWidth - plotWidth) / 2
}

function calculateYTranslation(
    layoutHeight: number,
    lastNode: any,
    nodeHeight: number,
    masterHeight: number) {
    const midPlot = masterHeight / 2
    return -1 * lastNode.ux + (masterHeight - midPlot) / 2 + nodeHeight
}


export default function ZoomI({ width, height, taskId, delay, scope, setShowDag, setActiveRedMilestone, setActiveGreenMilestone, driving }: ZoomIProps) {
    const [showMiniMap, setShowMiniMap] = useState<boolean>(true);
    const [dag, setDag] = useState<any | null>(null);
    const [layoutWidth, setLayoutWidth] = useState(0);
    const [layoutHeight, setLayoutHeight] = useState(0);
    const cpmMap = useCpmMapSelector()
    const projectCalendars = useActiveProjectCalendarsSelector()
    const dispatch = useDispatch()
    const queuedTasks = useQueuedTaskListSelector()
    const inProgressTasks = useInProgressTaskListSelector()
    const allTasks = [...queuedTasks, ...inProgressTasks]
    const [hoverTask, setHoverTask] = useState<string | null>(null)

    function getDateFromIndex(taskId: string) {
        const task = cpmMap.get(taskId)
        const date = convertIndexToSeconds(task?.ef, task?.cal_id, projectCalendars)
        if (task) {
            return new Date(date * 1000).toDateString()
        }
        return new Date().toDateString()
    }

    useEffect(() => {
        const data = generateDAGData(taskId);
        const dag = d3Dag.graphStratify()(data);
        const nodeRadius = 75; //need to change based on the nodeWidth value
        const layout = d3Dag
            .sugiyama() // base layout
            .layering(d3Dag.layeringLongestPath())
            .decross(d3Dag.decrossOpt()) // minimize number of crossings
            .nodeSize((node) => [(node ? 3.8 : 0.25) * nodeRadius, 3 * nodeRadius]); // set node size instead of constraining to fit
        const { width, height } = layout(dag as any);
        setDag(dag);
        setLayoutHeight(width);
        setLayoutWidth(height);
    }, [taskId, width]);

    const links = useMemo(() => {
        if (!dag) return [];
        let outLinks: any[] = []
        for (const link of dag.links()) {
            outLinks.push(link);
        }
        return outLinks
    }, [dag]);

    const nodes = useMemo(() => {
        if (!dag) return [];
        let outNodes: any[] = []
        for (const node of dag.nodes()) {
            outNodes.push(node);
        }
        return outNodes
    }, [dag]);

    const goToSuccessor = (taskId: any) => {
        const task = allTasks.filter(task => task.task_id === taskId)[0]
        dispatch(taskActions.Actions.setActiveTask(task))
        dispatch(
            taskActions.Actions.openRelatedTaskList(
                task,
                "predecessors"
            )
        );
    }

    const initialTransform = useMemo(() => {
        if (!dag) return {
            scaleX: 1.0,
            scaleY: 1.0,
            translateX: 0,
            translateY: 0,
            skewX: 0,
            skewY: 0,
        }
        const topological = dag.topological()
        const lastNode = topological[driving ? 0 : topological.length - 1]
        // const maxWidth = lastNode.uy - topological[0].uy
        const translateX = calculateXTranslation(topological[0], lastNode, nodeWidth, width)
        const translateY = calculateYTranslation(layoutHeight, lastNode, nodeHeight, height)
        return {
            scaleX: 1,
            scaleY: 1,
            translateX: translateX,
            translateY: translateY,
            skewX: 0,
            skewY: 0,
        }}, [dag])

    return dag ? (
            <Zoom<SVGSVGElement>
                width={layoutWidth}
                height={layoutHeight}
                scaleXMin={1 / 2}
                scaleXMax={4}
                scaleYMin={1 / 2}
                scaleYMax={4}
                initialTransformMatrix={initialTransform}
            >
                {(zoom) => (
                    <div className="relative">
                        <svg
                            width={width}
                            height={height}
                            style={{cursor: zoom.isDragging ? 'grabbing' : 'grab', touchAction: 'none'}}
                            ref={zoom.containerRef}
                        >
                            <defs>
                                <marker
                                    id="arrow"
                                    viewBox="0 -5 10 10"
                                    refX="15"
                                    refY="-.5"
                                    markerWidth="6"
                                    markerHeight="6"
                                    orient="auto"
                                    fill={lightpurple}
                                >
                                    <path d="M0,-5L10,0L0,5"/>
                                </marker>
                                    <filter id="f4" width="120" height="120">
                                        <feOffset in="SourceGraphic" dx="3" dy="3" />
                                        <feColorMatrix type="matrix" values = "0.2 0 0 0 0 0 0.2 0 0 0 0 0 0.2 0 0 0 0 0 1 0"/>
                                        <feGaussianBlur stdDeviation="2" />
                                        <feBlend in="SourceGraphic" in2="blurOut" />
                                    </filter>
                            </defs>
                            <RectClipPath id="zoom-clip" width={width} height={height}/>
                            <RectClipPath id={"node-clip"} width={nodeWidth} height={nodeHeight}/>
                            <rect width={width} height={height} fill={scope === "preds" ? "white" : delay ? redBg : bg}/>
                            <rect
                                width={width}
                                height={height}
                                rx={14}
                                fill="transparent"
                                onTouchStart={zoom.dragStart}
                                onTouchMove={zoom.dragMove}
                                onTouchEnd={zoom.dragEnd}
                                onMouseDown={zoom.dragStart}
                                onMouseMove={zoom.dragMove}
                                onMouseUp={zoom.dragEnd}
                                onMouseLeave={() => {
                                    if (zoom.isDragging) zoom.dragEnd();
                                }}
                                onDoubleClick={(event) => {
                                    const point = localPoint(event) || {x: 0, y: 0};
                                    zoom.scale({scaleX: 1.1, scaleY: 1.1, point});
                                }}
                            />
                            <Group transform={zoom.toString()}>
                                {links.map((link, i) => (
                                        <LinkHorizontal
                                            key={`link-${i}`}
                                            data={link}
                                            stroke={lightpurple}
                                            strokeWidth="1"
                                            fill="none"
                                            x={(node: any) => node.y - nodeWidth / 2 + 1}
                                            markerEnd="url(#arrow)"
                                        />)
                                )}
                                {nodes.map((node, i) => {
                                    const task = cpmMap.get(node.data.id)
                                    return (
                                    <Popup
                                        key={`node-${i}`}
                                        trigger={
                                            <Group
                                                top={node.ux}
                                                left={node.uy}
                                                style={{cursor: "pointer"}}
                                                onClick={() => {
                                                    goToSuccessor(node.data.id)
                                                    setShowDag(false)
                                                    setActiveGreenMilestone(null)
                                                    setActiveRedMilestone(null)
                                                }}
                                                onMouseOver={() => setHoverTask(node.data.id)}
                                                onMouseOut={() => setHoverTask(null)}
                                            >
                                                <rect
                                                    height={nodeHeight}
                                                    width={nodeWidth}
                                                    y={-nodeHeight / 2}
                                                    x={-nodeWidth / 2}
                                                    fill={node.data.id === taskId ? yellow : background}
                                                    opacity={1}
                                                    stroke={task?.predStatus === 1 ? predStatusGreen :
                                                        task?.predStatus === 2 ? predStatusYellow :
                                                            task?.predStatus === 3 ? predStatusRed : blue}
                                                    strokeWidth={2}
                                                    rx={5}
                                                    filter={"url(#f4)"}
                                                />
                                                {hoverTask === node.data.id && <rect
                                                    height={nodeHeight}
                                                    width={nodeWidth}
                                                    y={-nodeHeight / 2}
                                                    x={-nodeWidth / 2}
                                                    fill="grey"
                                                    opacity={0.4}
                                                    stroke={task?.predStatus === 1 ? predStatusGreen :
                                                        task?.predStatus === 2 ? predStatusYellow :
                                                            task?.predStatus === 3 ? predStatusRed : blue}
                                                    strokeWidth={2}
                                                    rx={5}
                                                    filter={"url(#f4)"}
                                                />}
                                                <foreignObject
                                                    width={nodeWidth}
                                                    height={nodeHeight}
                                                    x={-nodeWidth / 2}
                                                    y={-nodeHeight / 2}
                                                >
                                                    <div style={
                                                        {
                                                            display: "flex",
                                                            justifyContent: "center",
                                                            alignItems: "center",
                                                            height: nodeHeight,
                                                            width: nodeWidth,
                                                            overflow: "hidden",
                                                            pointerEvents: "none",
                                                        }
                                                    }>
                                                        <p style={{
                                                            pointerEvents: "none",
                                                            textAlign: "center",
                                                            fontSize: 12,
                                                        }}>
                                                            {task?.p6_id}
                                                        </p>
                                                    </div>
                                                </foreignObject>
                                                {/*<text*/}
                                                {/*    dy=".33em"*/}
                                                {/*    fontSize={11}*/}
                                                {/*    fontFamily="Arial"*/}
                                                {/*    textAnchor="middle"*/}
                                                {/*    fill="black"*/}
                                                {/*    style={{pointerEvents: "none"}}*/}
                                                {/*>*/}
                                                {/*    {task?.p6_id}*/}
                                                {/*</text>*/}
                                            </Group>
                                        }>
                                        <PopupHeader>{task?.wbs}</PopupHeader>
                                        <PopupContent>
                                            {`${task?.task_name} (${task?.p6_id})`}
                                            <br/>
                                            Early Finish: {getDateFromIndex(node.data.id)}
                                        </PopupContent>
                                    </Popup>
                                    )
                                })}
                            </Group>
                            {showMiniMap && (
                                <g
                                    clipPath="url(#zoom-clip)"
                                    transform={`
                    scale(0.25)
                    translate(${width * 4 - width - 60}, ${height * 4 - height - 60})
                  `}
                                >
                                    <Group transform={zoom.toString()}>
                                        {links.map((link, i) => (
                                            <LinkHorizontal
                                                key={`link-${i}-mini`}
                                                data={link}
                                                stroke={lightpurple}
                                                strokeWidth="1"
                                                fill="none"
                                                x={(node: any) => node.y - nodeWidth / 2 + 1}
                                                markerEnd="url(#arrow)"
                                            />)
                                        )}
                                        {nodes.map((node) => (
                                            <Group
                                                top={node.ux}
                                                left={node.uy}
                                            >
                                                <rect
                                                    height={nodeHeight}
                                                    width={nodeWidth}
                                                    y={-nodeHeight / 2}
                                                    x={-nodeWidth / 2}
                                                    fill={node.data.id === taskId ? yellow : background}
                                                    opacity={1}
                                                    strokeWidth={1}
                                                    rx={5}
                                                />
                                                <text
                                                    dy=".33em"
                                                    fontSize={9}
                                                    fontFamily="Arial"
                                                    textAnchor="middle"
                                                    fill="black"
                                                    style={{pointerEvents: "none"}}
                                                >
                                                    {cpmMap.get(node.data.id)?.p6_id}
                                                </text>
                                            </Group>
                                        ))}
                                    </Group>
                                    <rect
                                        width={width}
                                        height={height}
                                        fill="white"
                                        fillOpacity={0.2}
                                        stroke="white"
                                        strokeWidth={4}
                                        transform={zoom.toStringInvert()}
                                    />
                                </g>
                            )}
                        </svg>
                        <div className="controls">
                            <button
                                type="button"
                                className="btn btn-zoom"
                                onClick={() => zoom.scale({scaleX: 1.2, scaleY: 1.2})}
                            >
                                +
                            </button>
                            <button
                                type="button"
                                className="btn btn-zoom btn-bottom"
                                onClick={() => zoom.scale({scaleX: 0.8, scaleY: 0.8})}
                            >
                                -
                            </button>
                            <button type="button" className="btn btn-lg" onClick={zoom.center}>
                                Center
                            </button>
                            <button type="button" className="btn btn-lg" onClick={zoom.reset}>
                                Reset
                            </button>
                            <button type="button" className="btn btn-lg" onClick={zoom.clear}>
                                Clear
                            </button>
                        </div>
                        <div className="mini-map">
                            <button
                                type="button"
                                className="btn btn-lg"
                                onClick={() => setShowMiniMap(!showMiniMap)}
                            >
                                {showMiniMap ? 'Hide' : 'Show'} Mini Map
                            </button>
                        </div>
                    </div>
                )}
            </Zoom>
    ) : null;
}
