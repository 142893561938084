import React, {useMemo} from "react";
import styles from "./TaskIcon.module.scss";
import TaskModel, {ProcessedFrom} from "../../../../../../models/responses/task.model";
import TaskStatusModel from "../../../../../../models/responses/task-status.model";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCircle, faCog} from "@fortawesome/pro-solid-svg-icons";
import {faCog as faCogLight} from "@fortawesome/pro-light-svg-icons";
import classNames from "classnames";
import {TaskListSectionModel} from "../../../../../../models/task-list-section.model";

interface InjectedProps {
  task: TaskModel;
  taskListType: TaskListSectionModel | ProcessedFrom | null;
  spinningIcon: boolean;
  targetFronts: number;
}

export const TaskIcon: React.FC<InjectedProps> = ({
  task,
  taskListType,
  spinningIcon,
  targetFronts,
}) => {
  const predStatusClass = useMemo(() => {
    switch (task.predStatus) {
      case 1: {
        return styles.GreenIcon;
      }
      case 2: {
        return styles.YellowIcon;
      }
      case 3: {
        return styles.RedIcon;
      }
      default: {
        return null;
      }
    }
  }, [task.predStatus]);

  const inProgressStatusClass = useMemo(() => {
    return styles.DarkIcon;
  }, []);

  let isSolid = task.flow
  switch (task.status) {
    case TaskStatusModel.IN_PROGRESS:
        return (
          <FontAwesomeIcon
            icon={isSolid ? faCog : faCogLight}
            spin={!task.suspended && spinningIcon}
            className={classNames(
              styles.Icon,
              {
                [styles.Blinking]: task.suspended,
              },
              inProgressStatusClass
            )}
          />
        );
    case TaskStatusModel.SUSPENDED:
        return (
          <FontAwesomeIcon
            icon={isSolid ? faCog : faCogLight}
            className={classNames(
              styles.Icon,
              styles.Blinking,
              inProgressStatusClass
            )}
          />
        );
    case TaskStatusModel.BLOCK:
        return (
          <FontAwesomeIcon
            icon={isSolid ? faCog : faCircle}
            className={classNames(
              styles.Icon,
              styles.Blinking,
                !isSolid ? predStatusClass : null,
                {[styles.Block]: !isSolid}
            )}
          />
        );
    case TaskStatusModel.NOT_STARTED:
      if (taskListType === TaskListSectionModel.PENDING) {
        return (
          <FontAwesomeIcon
            icon={faCircle}
            className={classNames(styles.Icon, predStatusClass)}
            />
        );
      } else if (task.flow) {
        return (
            <FontAwesomeIcon
                icon={faCog}
                spin={false}
                className={classNames(
                    styles.Icon,
                    {
                      [styles.Blinking]: task.suspended,
                    },
                    inProgressStatusClass
                )}
            />
        );
      } else {
        return (
            <div className={styles.NotStarted}>
              <FontAwesomeIcon
                  icon={faCircle}
                  className={classNames(styles.Icon, predStatusClass)}
              />
            </div>
        );
      }
    case TaskStatusModel.DECLARED_COMPLETE:
      return <div className={classNames(styles.Complete, styles.Blinking)} />;
    case TaskStatusModel.COMPLETE:
      return <div className={styles.Complete} />;
    default:
      return null;
  }
};
