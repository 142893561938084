import React, {useEffect, useMemo, useState} from "react";
import styles from "./GanttChart.module.scss";
import {
    Button,
    Dimmer,
    Dropdown,
    DropdownDivider, DropdownHeader,
    DropdownItem,
    DropdownMenu,
    Loader,
    Modal,
    Popup
} from "semantic-ui-react";
import {useTypedSelector} from "../../../../store/selectors/selectors.utils";
import {
    useActiveProjectCalendarsSelector,
    useActiveProjectSelector,
    useCpmMapSelector
} from "../../../../store/selectors/project.selectors";
import {convertDateToIndex, convertIndexToSeconds} from "../../../../utils/cpm-functions/cpm-app/functions/handleEvent";
import moment from "moment";
import {CpmTaskModel} from "../../../../models/responses/cpm-task.model";
import {WbsModel} from "../../../../models/responses/wbs.model";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    faCaretDown,
    faCaretRight,
    faCircleCheck,
    faCircleHalfStroke,
    faCircleQuarterStroke,
    faCircleThreeQuartersStroke,
    faHandshake,
    faHandPointLeft,
    faMagnifyingGlassPlus,
    faMagnifyingGlassMinus, faUserPlus, faChevronLeft, faChevronRight
} from "@fortawesome/pro-solid-svg-icons";
import {faCircleCheck as faCircleCheckRegular} from "@fortawesome/pro-regular-svg-icons";
import {useActiveTaskSelector, useAllTaskListSelector} from "../../../../store/selectors/task/task.selectors";
import TaskModel from "../../../../models/responses/task.model";
import {useSearchParamatersSelector} from "../../../../store/selectors/search.selectors";
import filterTaskList from "../../../../utils/task-filtering";
import {useDispatch} from "react-redux";
import * as taskActions from "../../../../store/actions/task.actions";
import TimeScale from "./components/TimeScale";
import classNames from "classnames";
import TaskStatusModel from "../../../../models/responses/task-status.model";
import {faCircleRadiation} from "@fortawesome/pro-light-svg-icons";
import {faCircle, faCirclePause} from "@fortawesome/pro-regular-svg-icons";
import {TaskType} from "../../../../models/task-type";
import {TaskEventModal} from "../../pages/FlowPage/components/TaskEventModal";
import EventTextModel from "../../../../models/event-text-model.enum";
import {useUserGradeSelector, useUserSelector} from "../../../../store/selectors/authorization.selectors";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import {leaveTaskForce, wbsJoin, wbsLeave} from "../../../../utils/taskforce.utils";
import DatePicker from "react-datepicker";
import {Timestamp} from "firebase/firestore";
import CpmFunctionsController from "../../../../utils/cpm-functions/cpm-functions.controller";
import FirebaseUsage from "../../../../firebase/firebase.usage";
import TaskBar from "./components/TaskBar";
import UserImage from "../../../../images/UserImage";
import TaskDropdown from "./components/TaskDropdown";
import {useProjectMemberListSelector} from "../../../../store/selectors/team.selectors";
// import {MemberOrganizationEnum} from "../../../../models/member-organization.enum";
// import {set} from "d3";
import * as projectActions from "../../../../store/actions/project.actions";
import * as wbsActions from "../../../../store/actions/wbs.actions";


function generateQuarter(task: CpmTaskModel) {
    if (typeof task.es !== 'number' || typeof task.ef !== 'number') return null
    const start = task.es
    const finish = task.ef
    const duration = finish - start
    return Math.floor((1 - (task.duration / duration)) / 0.25)
}

function cursorSelector(task: TaskModel, userId: string) {
    if (!task.taskForce.includes(userId)) return "auto"
    switch (task.status) {
        case TaskStatusModel.COMPLETE:
            return "auto"
        case TaskStatusModel.DECLARED_COMPLETE:
            return "auto"
        case TaskStatusModel.IN_PROGRESS:
            return "pointer"
        case TaskStatusModel.NOT_STARTED:
            return "pointer"
        case TaskStatusModel.BLOCK:
            return "auto"
        case TaskStatusModel.SUSPENDED:
            return "auto"
        default:
            return "auto"
    }
}

function statusIcon(status: TaskStatusModel, quarter: number | null) {
    switch (status) {
        case TaskStatusModel.COMPLETE:
            return faCircleCheck
        case TaskStatusModel.DECLARED_COMPLETE:
            return faCircleCheckRegular
        case TaskStatusModel.IN_PROGRESS:
            if (quarter === 0) return faCircle
            if (quarter === 1) return faCircleQuarterStroke
            if (quarter === 2) return faCircleHalfStroke
            if (quarter === 3 || quarter === 4) return faCircleThreeQuartersStroke
            return faCircle
        case TaskStatusModel.NOT_STARTED:
            return faCircle
        case TaskStatusModel.BLOCK:
            return faCircleRadiation
        case TaskStatusModel.SUSPENDED:
            return faCirclePause
        default:
            return faCircleCheck
    }
}

function statusIconColour(task: TaskModel) {
    switch (task.status) {
        case TaskStatusModel.COMPLETE:
            // return '#0f9d58'
            return '#4285f4'
        case TaskStatusModel.DECLARED_COMPLETE:
            return '#4285f4'
        case TaskStatusModel.IN_PROGRESS:
            return '#b2b2b2'
        case TaskStatusModel.NOT_STARTED:
            if (task.task_type !== TaskType.TT_TASK && task.task_type !== TaskType.TT_RSRC) return '#d0cece'
            if (task.predStatus === 1) {
                return '#0f9d58'
            } else if (task.predStatus === 2) {
                return '#f4b400'
            } else {
                return '#d9001b'
            }
        case TaskStatusModel.BLOCK:
            return '#d9001b'
        case TaskStatusModel.SUSPENDED:
            return '#d9001b'
        default:
            return '#d0cece'
    }
}

export default function GanttChart() {
    const wbsList = useTypedSelector((state) => state.wbs.wbsList)
    const cpmMap = useCpmMapSelector()
    const projectCalendars = useActiveProjectCalendarsSelector()
    const [wbsDisplay, setWbsDisplay] = useState<WbsModel[]>([])
    const allTasks = useAllTaskListSelector()
    const searchParams = useSearchParamatersSelector()
    const dispatch = useDispatch()
    const [viewWidth, setViewWidth] = useState(window.innerWidth)
    const activeProject: any = useActiveProjectSelector()
    const activeTask = useActiveTaskSelector()
    const dataDate = Math.floor(new Date().getTime() / 1000 / 86400) * 86400
    const [sort, setSort] = useState<string>("start")
    const [contextHeading, setContextHeading] = useState<any | null>(null)
    const [contextTask, setContextTask] = useState<any | null>(null)
    const [modalOpened, setModalOpened] = useState<boolean>(false)
    const [eventTask, setEventTask] = useState<TaskModel | null>(null)
    const [eventText, setEventText] = useState<EventTextModel>(EventTextModel.COMPLETE_TASK)
    const user = useUserSelector()
    const [joinAll, setJoinAll] = useState<null | any>(null)
    const [loading, setLoading] = useState<boolean>(false)
    const [editTask, setEditTask] = useState<any | null>(null)
    const [popUpOpen, setPopUpOpen] = useState(false)
    const [popUpPosition, setPopUpPosition] = useState({x: 0, y: 0, start: 0, finish: 0})
    const [subMenu, setSubMenu] = useState<any | null>(null)
    const [suspendReason, setSuspendReason] = useState<string | null>(null)

    const {customViewStart, customViewFinish, earliestDate, latestDate} = activeProject
    const userIsAdmin = useUserGradeSelector()["Super-Actor"]
    const projectMembers = useProjectMemberListSelector()
    const [componentMounted, setComponentMounted] = useState(false)

    const [startDate, setStartDate] = useState(customViewStart ? customViewStart.toDate() : new Date(earliestDate))
    const [finishDate, setFinishDate] = useState(customViewFinish ? customViewFinish.toDate() : new Date(latestDate))
    const widthPast = (new Date().getTime() - startDate.getTime()) / (finishDate.getTime() - startDate.getTime()) * viewWidth * 0.5

    async function runEvent(taskId: string, eventText: string, eventTimestamp: Timestamp, expiryDate?, suspendReason?: string) {
        try {
            if (activeProject && user) {
                CpmFunctionsController.runTaskCPM({
                        project_id: activeProject.projectId,
                        task_id: taskId,
                        event_type: eventText,
                        seconds: eventTimestamp.seconds,
                        user_id: user.userId!,
                        user_email: user.userEmail,
                        expiry_date: expiryDate ? expiryDate.toString() : eventTimestamp.seconds.toString(),
                        suspend_reason: suspendReason || "null",
                        reason_text: "null",
                    },
                    dispatch
                ).then(() => {
                    console.log(`Task ${taskId} ${eventText} at ${eventTimestamp} successful`);
                })
            }
        } catch (e) {
            console.log(e);
        }
    }

    async function setActiveTaskOnClick(task: TaskModel) {
        dispatch(taskActions.Actions.setActiveTask(task))
    }

    useEffect(() => {
        if (activeTask) {
            const activeTaskWbs = () => {
                let wbsListToExpand: any = [activeTask.wbs_id]
                let wbs = wbsList.find((wbsItem) => wbsItem.wbs_id === activeTask.wbs_id)
                let parentWbs = wbsList.find((wbsItem) => wbsItem.wbs_id === wbs?.parent_wbs_id)
                while (parentWbs) {
                    wbsListToExpand.push(parentWbs.wbs_id)
                    parentWbs = wbsList.find((wbsItem) => wbsItem.wbs_id === parentWbs?.parent_wbs_id)
                }
                return wbsList.map((wbsItem) => {
                    if (wbsListToExpand.includes(wbsItem.wbs_id)) {
                        return {...wbsItem, expanded: true}
                    }
                    return wbsItem
                })
            }
            dispatch(wbsActions.Actions.setWBSList(activeTaskWbs()))
            setComponentMounted(true)
        }
    }, []);

    useEffect(() => {
        if (componentMounted && activeTask) {
            const element = document.getElementById(`task-${activeTask.task_id}`)
            if (element) {
                // element.scrollIntoView({block: "start", inline: "nearest"})

                // get x and y position of element
                const rect = element.getBoundingClientRect();
                const y = rect.top;

                const ganntElement = document.getElementById("gantt-chart")
                // window.moveTo(x, y);
                if (ganntElement) ganntElement.scrollTop = y - 400
            }
            setComponentMounted(false)
        }
    }, [componentMounted]);

    const filteredTasks: Map<string, TaskModel> = useMemo(() => {
        let taskMap = new Map<string, TaskModel>()
        filterTaskList(allTasks, searchParams).forEach((task: TaskModel) => {
            taskMap.set(task.task_id, task)
        })
        return taskMap
    }, [allTasks, searchParams])

    function generateTasks(taskIds: string[], level: number) {
        if (taskIds.length === 0) return null
        let taskStartDate: number | null = null
        let taskFinishDate: number | null = null
        const tasks = taskIds.map((taskId) => {
            const task: CpmTaskModel = cpmMap.get(taskId)
            const taskData: TaskModel | undefined = filteredTasks.get(taskId)
            if (!taskData) return null
            const workingHours = projectCalendars.get(`whd:${task.cal_id}`)
            const start = convertIndexToSeconds(task.es, task.cal_id, projectCalendars)
            const finish = convertIndexToSeconds(task.ef, task.cal_id, projectCalendars)
            if (taskStartDate === null) taskStartDate = start
            if (start < taskStartDate) taskStartDate = start
            if (taskFinishDate === null) taskFinishDate = finish
            if (finish > taskFinishDate) taskFinishDate = finish
            const sortDate = sort === "start" ? start ? start : new Date().getTime() / 1000 : finish ? finish : new Date().getTime() / 1000
            const quarter = generateQuarter(task)
            const icon = statusIcon(taskData.status, quarter)
            const iconColour = statusIconColour(taskData)
            const isTaskForce = taskData.taskForce.includes(user?.userId || "No user")

            return {element: (
                <tr key={taskId}
                    id={`task-${taskId}`}
                    className={classNames(styles.TaskRow,
                        {[styles.ActiveTask]: Boolean(activeTask?.task_id === taskId)})}
                    onClick={() => {
                        setEditTask(prevState =>
                            taskData.task_id === prevState?.taskId ? prevState : null)
                        setActiveTaskOnClick(taskData).catch(e => console.log(e))
                    }}
                    onContextMenu={(e) => {
                        e.preventDefault()
                        setContextTask({task: taskData, x: e.clientX, y: e.clientY})
                        setSubMenu(null)
                        setContextHeading(null)
                        setEditTask(prevState =>
                            taskData.task_id === prevState?.taskId ? prevState : null)
                        setActiveTaskOnClick(taskData).catch(e => console.log(e))
                    }}
                    // onMouseOver={() => setHoverTaskId(taskData.task_id)}
                    // onMouseLeave={() => setHoverTaskId(null)}
                >
                    <td className={styles.TaskNameCell} style={{minWidth: `${viewWidth * 0.36}px`, maxWidth: `${viewWidth * 0.36}px`}}
                        align={'left'}><span className={styles.TaskNameContainer}
                        onDoubleClick={() => setEditTask({taskId: taskData?.task_id, scope: "name", name: taskData?.task_name})}
                    >
                        <FontAwesomeIcon className={styles.PredButton} icon={faChevronLeft}
                                         onClick={() => {
                                             dispatch(taskActions.Actions.setActiveTask(taskData));
                                             dispatch(projectActions.Actions.setView("task"));
                                             dispatch(
                                                 taskActions.Actions.openRelatedTaskList(
                                                     taskData,
                                                     "predecessors"
                                                 )
                                             );
                                         }}
                        />
                        <div style={{
                            minWidth: `${40 + (level * 10)}px`,
                            maxWidth: `${40 + (level * 10)}px`,
                        }}> </div>
                        <FontAwesomeIcon icon={icon} className={styles.CompleteSymbol} style={{color: `${iconColour}`, cursor: cursorSelector(taskData, user?.userId || "")}}
                                         onClick={() => {
                                             if (taskData?.status === TaskStatusModel.COMPLETE || taskData?.status === TaskStatusModel.DECLARED_COMPLETE || !isTaskForce) return
                                             if  (taskData.status === TaskStatusModel.IN_PROGRESS) setEventText(EventTextModel.COMPLETE_TASK)
                                             if (taskData.status === TaskStatusModel.NOT_STARTED) setEventText(EventTextModel.START_TASK)
                                             setEventTask(taskData)
                                             setModalOpened(true)
                                         }} />
                        {isTaskForce && userIsAdmin &&
                            <Dropdown
                                icon={null}
                                trigger={
                            <UserImage user={user!} width={18} height={18}/>}
                                >
                                <DropdownMenu>
                                    <DropdownItem onClick={() =>
                                        leaveTaskForce(taskData.task_id, user?.userId || "", user?.userEmail || "", FirebaseUsage.timestamp())}
                                    >Leave Taskforce
                                    </DropdownItem>
                                </DropdownMenu>
                            </Dropdown>}
                        {isTaskForce && userIsAdmin && editTask && editTask.taskId === task.task_code && editTask.scope === "name" ?
                            <input type={"text"} value={editTask.name} autoFocus
                                   style={{width: "100%"}}
                                  onChange={(e) => setEditTask({...editTask, name: e.target.value})}
                                   onKeyDown={(e) => {
                                       if (e.key === "Enter") {
                                           if (editTask.name !== taskData.task_name && editTask.name !== "") {
                                               runEvent(taskData?.task_id, "rename", FirebaseUsage.timestamp(), null, editTask.name)
                                                   .then(() => setEditTask(null))
                                                   .catch((e) => console.log(e))
                                           } else {
                                               setEditTask(null)
                                           }
                                       } else if (e.key === "Escape") {
                                             setEditTask(null)
                                       }
                                   }
                                   }
                                  onBlur={() => {
                                      if (editTask.name !== taskData.task_name && editTask.name !== "") {
                                        runEvent(taskData?.task_id, "rename", FirebaseUsage.timestamp(), null, editTask.name)
                                             .then(() => setEditTask(null))
                                             .catch((e) => console.log(e))
                                      } else {
                                          setEditTask(null)
                                      }
                                  }}
                            /> :
                            `${taskData.task_code} - ${taskData?.task_name}`}
                        <FontAwesomeIcon className={styles.SuccButton} icon={faChevronRight}
                                         onClick={() => {
                                             dispatch(taskActions.Actions.setActiveTask(taskData));
                                             dispatch(projectActions.Actions.setView("task"));
                                             dispatch(
                                                 taskActions.Actions.openRelatedTaskList(
                                                     taskData,
                                                     "successors"
                                                 )
                                             );
                                         }}
                        />
                    </span></td>
                    <td className={styles.TaskStartDateCell} style={{textAlign: "center"}}
                        align={'center'}
                        onClick={() => {
                            setEditTask({taskId: taskData?.task_id, scope: "start"})}}
                    >{
                        editTask?.taskId === taskData?.task_id &&
                        editTask.scope === "start" &&
                        task.status_code === TaskStatusModel.NOT_STARTED &&
                            isTaskForce && userIsAdmin ?
                            <DatePicker
                                className={styles.DatePicker}
                                selected={new Date(start * 1000)}
                                dateFormat="dd-MMM-yyyy"
                                autoFocus
                                enableTabLoop={false}
                                onChange={(date) => {
                                    if (date) {
                                        const seconds = FirebaseUsage.timestamp(date)
                                        runEvent(taskData?.task_id, "constrain", seconds)
                                            .then(() => setEditTask(null))
                                            .catch((e) => console.log(e))
                                    }
                                }}
                            /> :
                        moment(new Date(start * 1000)).format('DD-MMM-YYYY')}</td>
                    <td className={styles.TaskFinishDateCell} style={{textAlign: "center"}}
                        align={'center'}
                        onClick={() => setEditTask({taskId: taskData?.task_id, scope: "finish"})}
                    >{
                        editTask?.taskId === taskData?.task_id &&
                        editTask.scope === "finish" && task.status_code !== TaskStatusModel.COMPLETE &&
                        task.status_code !== TaskStatusModel.DECLARED_COMPLETE &&
                        isTaskForce && userIsAdmin ?
                            <DatePicker
                                className={styles.DatePicker}
                                selected={new Date(finish * 1000)}
                                dateFormat="dd-MMM-yyyy"
                                autoFocus
                                enableTabLoop={false}
                                onChange={(date) => {
                                    if (date) {
                                        const seconds = FirebaseUsage.timestamp(date)
                                        const newFinish = convertDateToIndex(seconds.seconds, task.cal_id, projectCalendars)
                                        const remainingDuration = typeof task.es === 'number' ? (newFinish - task.es) * 1800 : 0
                                        runEvent(taskData?.task_id, "duration", seconds, remainingDuration, "none")
                                            .then(() => setEditTask(null))
                                            .catch((e) => console.log(e))
                                    }
                                }}
                            /> :
                        moment(new Date(finish * 1000)).format('DD-MMM-YYYY')}</td>
                    <td style={{height: "1px"}}>
                        <TaskBar
                            taskData={taskData}
                            task={task}
                            viewWidth={viewWidth}
                            viewStart={startDate}
                            viewFinish={finishDate}
                            dataDate={dataDate}
                            isTaskForce={isTaskForce}
                            projectCalendars={projectCalendars}
                            runEvent={runEvent}
                            setPopUpOpen={setPopUpOpen}
                            setPopUpPosition={setPopUpPosition}
                            userIsAdmin={userIsAdmin}
                        />
                    </td>
                </tr>
            ), sortDate}
        }).filter((task) => task !== null)
            .sort((a, b) => a!.sortDate - b!.sortDate)
        if (tasks.length === 0) return null
        return {elements: tasks.map(task => task!.element), taskStartDate, taskFinishDate}
    }

    function handleExpandWBS(wbsId: string) {
        dispatch(wbsActions.Actions.setWBSList(wbsList.map((wbsItem) => {
            if (wbsItem.wbs_id === wbsId) {
                return {...wbsItem, expanded: !wbsItem.expanded}
            }
            return wbsItem
        })));
    }

    function handleExpandAll(wbsCode: string) {
        dispatch(wbsActions.Actions.setWBSList(wbsList.map((wbsItem) => {
            if (wbsItem.wbs_code?.includes(wbsCode)) {
                return {...wbsItem, expanded: true}
            }
            return wbsItem
        })));
    }

    function handleCollapseAll(wbsCode: string) {
        dispatch(wbsActions.Actions.setWBSList(wbsList.map((wbsItem) => {
            if (wbsItem.wbs_code?.includes(wbsCode)) {
                return {...wbsItem, expanded: false}
            }
            return wbsItem
        })));
    }

    function generateWBSHeadings(wbsItem: WbsModel) {
        if (wbsItem.children.length === 0 && wbsItem.tasks.length === 0) return null
        const tasks = generateTasks(wbsItem.tasks, wbsItem.level)
        const wbsHeadings = wbsItem.children.map((childId) => {
            const childWbs =
                wbsList.find((wbsItem) => wbsItem.wbs_id === childId)
            if (!childWbs) return null
            return generateWBSHeadings(childWbs)
        }).filter((child) => child !== null)

        const children = tasks ?
            wbsHeadings.length > 0 ?
                tasks.elements.concat(wbsHeadings.map(heading => heading?.elements)) :
                tasks.elements : wbsHeadings.length > 0 ?
                wbsHeadings.map(heading => heading?.elements) :
                null
        if (!children) return null

        let wbsStart: number | null = null
        let wbsFinish: number | null = null
        if (tasks?.taskStartDate) wbsStart = tasks.taskStartDate
        if (tasks?.taskFinishDate) wbsFinish = tasks.taskFinishDate
        wbsHeadings.forEach((heading) => {
            if (heading?.wbsStart && (!wbsStart || heading.wbsStart < wbsStart)) wbsStart = heading.wbsStart
            if (heading?.wbsFinish && (!wbsFinish || heading.wbsFinish > wbsFinish)) wbsFinish = heading.wbsFinish
        })

        const thisHeading = (
            <tr className={classNames(
                styles.WBSHeaderRow,
                {[styles.HoveredHeading]: Boolean(contextHeading?.wbsId === wbsItem.wbs_code)})
            }
                key={wbsItem.wbs_id} style={{height: "1px"}}
                onContextMenu={(e) => {
                    e.preventDefault()
                    setContextHeading({wbsId: wbsItem.wbs_code, x: e.clientX, y: e.clientY, id: wbsItem.wbs_id})
                    setContextTask(null)
                }}
            >
                <td className={styles.WBSNameCell} style={{height: "inherit"}}>
                    <span className={styles.WBSHeaderContainer}>
                        <div style={wbsItem.level !== 0 ? {
                            minWidth: `${5 + (wbsItem.level * 10)}px`,
                            maxWidth: `${5 + (wbsItem.level * 10)}px`,
                        } : {}}></div>
                        <FontAwesomeIcon icon={wbsItem.expanded ? faCaretDown : faCaretRight}
                                         className={styles.ExpandIcon}
                                         onClick={() => handleExpandWBS(wbsItem.wbs_id)}
                        />
                        <p>{wbsItem.wbs_name}</p>
                    </span>
                </td>
                <td className={styles.WBSStartDateCell} style={{height: "1px"}} align={'center'}>{wbsStart ? moment(new Date(wbsStart * 1000)).format('DD-MMM-YYYY') : null}</td>
                <td className={styles.WBSFinishDateCell} style={{height: "1px"}} align={'center'}>{wbsFinish ? moment(new Date(wbsFinish * 1000)).format('DD-MMM-YYYY') : null}</td>
                <td style={{height: "1px"}}>
                    <div className={styles.GanttChartCellContainer} style={{
                        minWidth: `${viewWidth * 0.5}px`,
                        maxWidth: `${viewWidth * 0.5}px`,
                        height: "100%"
                    }}>
                        {dataDate * 1000 > startDate.getTime() && <div className={styles.GanttChartCellPast}
                             style={{
                                 minWidth: `${widthPast}px`,
                                 maxWidth: `${widthPast}px`,
                        }}>
                            {wbsStart && wbsStart < dataDate && wbsFinish ?
                                wbsStart === wbsFinish ?
                                <div
                                    className={styles.SummaryBarMilestone}
                                    style={{
                                        left: `${((((wbsStart * 1000) - startDate.getTime()) / (finishDate.getTime() - startDate.getTime())) * viewWidth * 0.5) - 6}px`
                                    }}
                                ></div> :
                                <div
                                    className={wbsFinish < dataDate ? styles.SummaryBarTotalPast : styles.SummaryBarPast}
                                    style={{
                                        minWidth: `${Math.max((((Math.min(dataDate, wbsFinish, finishDate.getTime() / 1000) * 1000) - (wbsStart * 1000)) / (finishDate.getTime() - startDate.getTime())) * viewWidth * 0.5, 5)}px`,
                                        maxWidth: `${(((Math.min(dataDate, wbsFinish, finishDate.getTime() / 1000) * 1000) - (wbsStart * 1000)) / (finishDate.getTime() - startDate.getTime())) * viewWidth * 0.5}px`,
                                        left:`${(((wbsStart * 1000) - startDate.getTime()) / (finishDate.getTime() - startDate.getTime())) * viewWidth * 0.5}px`
                                    }}
                                ></div> : null
                            }
                        </div>}
                        {dataDate * 1000 < finishDate.getTime() && <div className={styles.GanttChartCellFuture}
                             style={{
                                 minWidth: `${(viewWidth * 0.5) - widthPast}px`,
                                 maxWidth: `${(viewWidth * 0.5) - widthPast}px`,
                             }}>
                            {wbsFinish && wbsStart && wbsFinish > dataDate ?
                                wbsStart === wbsFinish ?
                                <div
                                    className={styles.SummaryBarMilestone}
                                    style={{
                                        left: `${dataDate >= wbsStart ? 0 :
                                            ((((wbsStart * 1000) - Math.max(dataDate * 1000, startDate.getTime())) / (finishDate.getTime() - startDate.getTime())) * viewWidth * 0.5) - 6
                                        }px`
                                    }}>
                                </div> :
                                <div
                                    className={wbsStart <= dataDate ? styles.SummaryBarFuture : styles.SummaryBarTotalFuture}
                                    style={{
                                        minWidth: `${Math.max((((wbsFinish * 1000) - (Math.max(dataDate, wbsStart, startDate.getTime() / 1000) * 1000)) / (finishDate.getTime() - startDate.getTime())) * viewWidth * 0.5, 5)}px`,
                                        maxWidth: `${(((wbsFinish * 1000) - (Math.max(dataDate, wbsStart, startDate.getTime() / 1000) * 1000)) / (finishDate.getTime() - startDate.getTime())) * viewWidth * 0.5}px`,
                                        left: `${dataDate >= wbsStart ? 0:
                                            ((((wbsStart * 1000) - Math.max(dataDate * 1000, startDate.getTime())) / (finishDate.getTime() - startDate.getTime())) * viewWidth * 0.5)
                                        }px`
                                    }}></div> : null
                            }
                        </div>}
                    </div>
                </td>
            </tr>
        )
        return wbsItem.expanded ? {elements: [thisHeading, ...children], wbsStart, wbsFinish} : {elements: thisHeading, wbsStart, wbsFinish}
    }

    const level0Wbs = useMemo(() =>
            wbsList.filter((wbsItem) => wbsItem.level === 0),
        [wbsList, sort])

    // add an event listener that changes the width of the view when the window is resized
    useEffect(() => {
        window.addEventListener('resize', () => {
            setViewWidth(window.innerWidth)
        })
    }, [])

    function printDocument() {
        const input = document.getElementById('gantt-for-print');

        if (input) {
            html2canvas(input)
                .then((canvas) => {
                    const imgData = canvas.toDataURL('image/png');
                    const xScaler = 0.78;
                    const yScaler = 0.8;
                    const pdf = new jsPDF(
                        {
                            orientation: 'portrait',
                            unit: 'pt',
                            format: [canvas.width * xScaler, canvas.height * yScaler]
                            // format: 'a3'
                        });

                    pdf.addImage(imgData, 'JPEG', 10, 10, 0 ,0, '', 'FAST');
                    pdf.output('dataurlnewwindow');
                    pdf.save("download.pdf");
                });
        }
    }

    return (
        <div className="lazy-load-container" id={"gantt-chart"}>
            <div className={styles.GanttChartContainer} id="gantt-for-print">
                <table className={styles.GanttChartTable} cellPadding="0" cellSpacing="0">
                    <thead>
                        <tr className={styles.HeaderRow} style={{padding: 0, margin: 0, zIndex: 300}}>
                            <th className={styles.HeaderCell} style={{minWidth: `${viewWidth * 0.36}px`, maxWidth: `${viewWidth * 0.36}px`}} align={'left'}>
                                Task Name
                                {/*<button onClick={() => printDocument()}>print</button>*/}
                            </th>
                            <th className={styles.HeaderCell}
                                style={{textAlign: "center", minWidth: `${viewWidth * 0.07}px`, maxWidth: `${viewWidth * 0.07}px`, cursor: "pointer"}}
                                align={'center'}
                                onClick={() => setSort("start")}>
                                Start Date{sort === "start" ? <FontAwesomeIcon icon={faCaretDown} style={{marginLeft: "5px"}} /> : null}
                            </th>
                            <th className={styles.HeaderCell}
                                style={{textAlign: "center", minWidth: `${viewWidth * 0.07}px`, maxWidth: `${viewWidth * 0.07}px`, cursor: "pointer"}}
                                align={'center'}
                                onClick={() => setSort("finish")}>
                                End Date{sort === "finish" ? <FontAwesomeIcon icon={faCaretDown} style={{marginLeft: "5px"}} /> : null}
                            </th>
                            <th style={{minWidth: "50%", maxWidth: "50%", paddingBottom: "0px", backgroundColor: "#f6f5f6"}}>
                                <TimeScale
                                    width={viewWidth * 0.5}
                                    height={70}
                                    start={startDate}
                                    finish={finishDate}
                                    setStartDate={setStartDate}
                                    setFinishDate={setFinishDate}
                                />
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {level0Wbs.map((wbsItem) => generateWBSHeadings(wbsItem)?.elements)}
                    </tbody>
                </table>
            </div>
            {contextHeading && <Dropdown
                open
                selectOnBlur={false}
                onBlur={(e) => {
                    if (!e.relatedTarget) {
                        setContextHeading(null)
                    }}
                }
                closeOnBlur={false}
                onClose={() => {
                    setContextHeading(null)
                }}
                icon={null}
                style={{position: "fixed", top: contextHeading?.y, left: contextHeading?.x}}
            >
                <Dropdown.Menu>
                    <Dropdown.Header>WBS Options</Dropdown.Header>
                    <Dropdown.Item onClick={() => handleExpandAll(contextHeading.wbsId)}>
                        <FontAwesomeIcon icon={faMagnifyingGlassPlus} style={{marginRight: "8px"}}/>Expand All
                    </Dropdown.Item>
                    <Dropdown.Item onClick={() => handleCollapseAll(contextHeading.wbsId)}>
                        <FontAwesomeIcon icon={faMagnifyingGlassMinus} style={{marginRight: "8px"}}/>Collapse All
                    </Dropdown.Item>
                    <DropdownDivider />
                    <Dropdown.Item onClick={() => setJoinAll({...contextHeading, type: "join"})}>
                        <FontAwesomeIcon icon={faHandshake} style={{marginRight: "8px"}}/>Join All
                    </Dropdown.Item>
                    <Dropdown.Item onClick={() => setJoinAll({...contextHeading, type: "leave"})}>
                    <FontAwesomeIcon icon={faHandPointLeft} style={{marginRight: "8px"}}/>Leave All</Dropdown.Item>
                    <div><FontAwesomeIcon icon={faUserPlus} style={{marginRight: "8px", marginLeft: "15px"}}/><Dropdown
                        style={{width: "160px", padding: "10px 0 10px 0"}}
                            // clearable
                            // fluid
                            search
                            // selection
                            // multiple
                        text={"Assign User to All"}
                        labeled
                        icon={null}
                            pointing={"top"}
                            // error={missing.includes("WBS Heading") && !wbsHeading}
                            options={projectMembers
                                .map((el) => ({
                                            key: el.userId, text: el.userEmail, value: el.userId, onClick: () => {
                                                setJoinAll({...contextHeading,
                                                    type: "assign",
                                                    userId: el.userId,
                                                    userEmail: el.userEmail})
                                    }
                                }))}
                            placeholder="Project members.."
                    /></div>
                </Dropdown.Menu>

            </Dropdown>}
            {eventTask && modalOpened &&
                <TaskEventModal eventText={eventText}
                                opened={modalOpened}
                                setOpened={setModalOpened}
                                task={eventTask}
                                suspendReason={suspendReason || "No reason"}
                                // evaluationResult={"No result"}
                                defaultDate={''} />
            }
            {joinAll &&
                <Modal centered size="mini" open={joinAll !== null} onClose={() => setJoinAll(null)}>
                    <Modal.Content>
                        <Modal.Description style={{textAlign: "center"}}>
                            {joinAll.type === "join" ? <h4>Joining all tasks in this WBS?</h4> :
                                joinAll.type === "leave" ? <h4>Leaving all tasks in this WBS?</h4> :
                                    joinAll.type === "assign" ? <h4>Assigning <b>{joinAll.userEmail}</b> to all tasks in this WBS?</h4> :
                                        <h4>Removing <b>{joinAll.userEmail}</b> from all tasks in this WBS?</h4>}
                        </Modal.Description>
                    </Modal.Content>
                    <Modal.Actions style={{textAlign: "center"}}>
                        <Button negative onClick={() => setJoinAll(null)}>Cancel</Button>
                        <Button positive
                                onClick={() => {
                                    setLoading(true)
                                    if (joinAll.type === "join") {
                                        wbsJoin(joinAll?.id || "",
                                            wbsList,
                                            user?.userId || "",
                                            user?.userId || "",
                                            allTasks,
                                            cpmMap,
                                            user?.userEmail || "")
                                            .then(() => setLoading(false))
                                    } else if (joinAll.type === "assign") {
                                        wbsJoin(joinAll?.id || "",
                                            wbsList,
                                            joinAll?.userId || "",
                                            user?.userId || "",
                                            allTasks,
                                            cpmMap,
                                            joinAll?.userEmail || "")
                                            .then(() => setLoading(false))
                                    } else if (joinAll.type === "leave") {
                                        wbsLeave(joinAll?.id || "",
                                            wbsList,
                                            user?.userId || "",
                                            user?.userId || "",
                                            allTasks,
                                            cpmMap,
                                            user?.userEmail || "")
                                            .then(() => setLoading(false))
                                    }
                                    setJoinAll(null)
                                    setContextHeading(null)
                                }}
                        >Confirm</Button>
                    </Modal.Actions>
                </Modal>
            }
            {loading &&
                <Dimmer active>
                    <Loader />
                </Dimmer>
            }
            {popUpOpen &&
                <div className={styles.DatePopup} style={{position: "fixed", top: popUpPosition.y - 25, left: popUpPosition.x + 8}} >
                    <div className={styles.PopupDate}><p>Start:</p> <p>{moment(new Date(popUpPosition.start * 1000)).format('ddd DD-MMM-YYYY')}</p></div>
                    <div className={styles.PopupDate}><p>Finish:</p> <p>{moment(new Date(popUpPosition.finish * 1000)).format('ddd DD-MMM-YYYY')}</p></div>
                </div>}
            {contextTask &&
                <TaskDropdown
                    contextTask={contextTask}
                    setContextTask={setContextTask}
                    userId={user?.userId || "No user"}
                    setEventTask={setEventTask}
                    setModalOpened={setModalOpened}
                    setEventText={setEventText}
                    setSubMenu={setSubMenu}
                />
            }
            {subMenu &&
                <Dropdown icon={null} scrolling open={subMenu} style={{position: "fixed", top: subMenu.y, left: subMenu.x}}
                            onClose={() => setSubMenu(null)} onBlur={() => setSubMenu(null)}
                >
                    <Dropdown.Menu>
                        {activeProject.issueCategories.map((category) => {
                            return <Dropdown.Item key={category.reason} onClick={() => {
                                setSubMenu(false)
                                setEventTask(subMenu.task)
                                setModalOpened(true)
                                setEventText(subMenu.type === "block" ? EventTextModel.BLOCK_TASK : EventTextModel.SUSPEND_TASK)
                                setSuspendReason(category.reason)
                            }}>{category.text}</Dropdown.Item>
                        })}
                    </Dropdown.Menu>
                </Dropdown>
            }
        </div>
    );
}
