import React, {useState} from "react";
import {
  faChartNetwork,
  faFileSpreadsheet,
  faSignOut,
} from "@fortawesome/pro-light-svg-icons";
import { faFilePlus } from "@fortawesome/pro-solid-svg-icons";

import ProjectModel from "../../../../../models/responses/project.model";
import { NavButton } from "./NavButton";
import { useUserSelector } from "../../../../../store/selectors/authorization.selectors";
import { MemberOrganizationEnum } from "../../../../../models/member-organization.enum";
import { NavBarLeaveProjectModal } from "./NavigationBarModals/NavBarLeaveProjectModal";
import { useProjectMemberListSelector } from "../../../../../store/selectors/team.selectors";
import { NavBarCloseProjectModal } from "./NavigationBarModals/NavBarCloseProjectModal";
import { generateSpreadsheet } from "../../../../../utils/generate-spreadsheet";
import {Button, Header, Modal, Form, Grid, Label, Loader, Dimmer} from "semantic-ui-react";
import FirebaseProjectFunctions from "../../../../../firebase/firebase-functions/project-service";
// import { generateP6Import } from "../../../../../utils/generateP6Import";
import {NavBarDataDateModal} from "./NavigationBarModals/NavBarDataDateModal";
import UsageModal from "../../UsageModal/UsageModal";
import {
  useCompleteTaskListSelector, useDeclaredCompleteTaskListSelector,
  useInProgressTaskListSelector, useQueuedTaskListSelector
} from "../../../../../store/selectors/task/task.selectors";
import createP6Xml from "../../../../../utils/export-functions/functions/createP6Xml";
// import {useRelationshipsSelector} from "../../../../../store/selectors/relationships/relations.selectors";
import {useTypedSelector} from "../../../../../store/selectors/selectors.utils";
import {
  useActiveProjectCalendarsSelector,
  useActiveProjectSelector,
  useCpmMapSelector
} from "../../../../../store/selectors/project.selectors";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import ManageBaselineModal from "./ManageBaselineModal/ManageBaselineModal";

interface InjectedProps {
  userGrade: MemberOrganizationEnum;
  leaveProject: () => void;
  updateStrategy: () => void;
  project: ProjectModel;
  nominatedMembersList: { userEmail: string; id: string }[];
  setNominatedMembersList(args: { userEmail: string; id: string }[]): void;
}

export const NavigationBarContent: React.FC<InjectedProps> = (props) => {
  // const dispatch = useDispatch();
  const [isModalOpened, setIsModalOpened] = useState<boolean>(false);
  const [isFloatOpended, setIsFloatOpended] = useState<boolean>(false);
  const [dataDateModalOpened, setDataDateModalOpened] = useState<boolean>(false)
  const [isTargetFrontOpended, setIsTargetFrontOpended] =
    useState<boolean>(false);
  const [isBaselineAssignOpen, setIsBaselineAssignOpen] = useState<boolean>(false)
  const [baselinName, setBaselineName] = useState<string>('')
  const [baselineNameValid, setBaselineNameValid] = useState<boolean>(false)
  const [baselineFile, setBaselineFile] = useState<string>("No file selected")
  const [baselineNamePrompt, setBaselineNamePrompt] = useState<string>("Baseline name must unique and contain at least 3 characters")
  const [baselineContent, setBaselineContent] = useState<string>("")
  const [showManageBaselines, setShowManageBaselines] = useState<boolean>(false)
  const [status, setStatus] = useState<string>("")
  const [floatValue, setFloatValue] = useState<string>("");
  const [factorValue, setFactorValue] = useState<string>(
    props.project.targetFronts
      ? props.project.targetFronts.toString()
      : (7 / 5).toString()
  );
  const [showUsageModal, setShowUsageModal] = useState<boolean>(false);
  const projectMembersList = useProjectMemberListSelector();
  const user = useUserSelector();
  const confirmedCompleteTasks = useCompleteTaskListSelector()
  const declaredCompleteTasks = useDeclaredCompleteTaskListSelector()
  const inProgressTasks = useInProgressTaskListSelector()
  const queuedTasks = useQueuedTaskListSelector()

  const relationships = useTypedSelector((state) => state.relationships.relationshipsList)
  const calendarMap = useActiveProjectCalendarsSelector()
  const wbs = useTypedSelector((state) => state.wbs.wbsList)
  const cpmMap = useCpmMapSelector()
  const activeProject = useActiveProjectSelector()
  const calendars = activeProject ? activeProject.calendars : []
  const [downloading, setDownloading] = useState<boolean>(false)
  // const dispatch = useDispatch();
  // const searchParameters = useSearchParamatersSelector();

  // const viewIssuesHandler = useCallback(() => {
  //     dispatch(SearchActions.toggleAlert());
  // }, [dispatch]);

  const downloadData = async () => {
    setDownloading(true);
    return generateSpreadsheet(props.project).then(() => setDownloading(false));
  }

  // const downloadData = useCallback(async () => {
  //
  //   await generateSpreadsheet(props.project);
  // }, [props.project]);

  // let fileInput: any = useRef(null);
  // function selectFile() {
  //   fileInput.current.click();
  // }
  //
  // function showFile(e) {
  //   e.preventDefault()
  //   generateP6Import(props.project, e)
  //       .then(() => {
  //         fileInput.current.value = null
  //       })
  //       .catch((err) => console.log(err))
  // }

  if (!user) {
    return null;
  }

  async function handleProjectContent(file) {
    const content = await file
    setBaselineContent(content)
  }


  const updateProjectFloat = async () => {
    setIsFloatOpended(false);
    await FirebaseProjectFunctions.updateProjectFloat({
      projectId: props.project.projectId,
      float: floatValue,
    });
    setFloatValue("");
  };

  const updateTargetFrontFactor = async () => {
    setIsTargetFrontOpended(false);
    await FirebaseProjectFunctions.updateTargetFrontFactor({
      projectId: props.project.projectId,
      tfFactor: factorValue,
    });
    setFactorValue("");
  };

  function handleDataDateOpen() {
    setDataDateModalOpened(true)
  }

  const userIsProjectOwner = activeProject?.projectOwners?.includes(user.userId!)

  return (
    <>
      <div className="navigation-bar-dropdown-content">
        {props.userGrade === MemberOrganizationEnum.SUPER_ACTOR &&
        userIsProjectOwner ? (
          <NavButton
            text="Update strategy"
            icon={faChartNetwork}
            buttonProps={{ onClick: () => props.updateStrategy() }}
          />
        ) : null}
        {/*{props.userGrade === MemberOrganizationEnum.SUPER_ACTOR &&*/}
        {/*user?.enableSA ? (*/}
        {/*    <NavButton*/}
        {/*        text="Backup All"*/}
        {/*        icon={faChartNetwork}*/}
        {/*        buttonProps={{ onClick: () => FirebaseProjectFunctions.backupAllProjects().catch(e => console.log(e)) }}*/}
        {/*    />*/}
        {/*) : null}*/}
        {props.userGrade === MemberOrganizationEnum.SUPER_ACTOR && activeProject &&
        userIsProjectOwner ? (
            <NavButton
                text="Assign baseline"
                icon={faChartNetwork}
                buttonProps={{ onClick: () => setIsBaselineAssignOpen(true)}}
            />
        ) : null}
        {props.userGrade === MemberOrganizationEnum.SUPER_ACTOR && activeProject &&
        userIsProjectOwner && activeProject.baselines && activeProject.baselines.length > 0 ? (
            <NavButton
                text="Manage baselines"
                icon={faChartNetwork}
                buttonProps={{ onClick: () => setShowManageBaselines(true)}}
            />
        ) : null}
        {props.userGrade === MemberOrganizationEnum.SUPER_ACTOR ? (
          <NavButton
            text="Download ledger"
            icon={faFileSpreadsheet}
            buttonProps={{ onClick: downloadData }}
          />
        ) : null}
        {props.userGrade === MemberOrganizationEnum.SUPER_ACTOR ? (
            <NavButton
              text="Download P6 progress import"
              icon={faFileSpreadsheet}
              buttonProps={{ onClick: handleDataDateOpen}}
            />
          // <div>
          //   <input
          //     type="file"
          //     style={{ display: "none" }}
          //     ref={fileInput}
          //     accept=".xlsx"
          //     onChange={(e) => showFile(e)}
          //   />
          //   <NavButton
          //     text="Download P6 progress import"
          //     icon={faFileSpreadsheet}
          //     buttonProps={{ onClick: selectFile }}
          //   />
          // </div>
        ) : null}
        <NavButton
            text="Download as P6 File (xml)"
            icon={faFileSpreadsheet}
            buttonProps={{ onClick: () => createP6Xml(
                [...confirmedCompleteTasks, ...declaredCompleteTasks, ...inProgressTasks, ...queuedTasks],
                  calendarMap, relationships, wbs, cpmMap, calendars, activeProject) }}
        />
        {/*<NavButton
                    classes={searchParameters.alert ? 'btn-active' : ''}
                    text="View Interruptions"
                    icon={faHourglass}
                    buttonProps={{
                        onClick: () => viewIssuesHandler()
                    }}
                />*/}
        {/* {props.userGrade === MemberOrganizationEnum.SUPER_ACTOR
                        ? <NavButton text='Disengage pull system [BETA]' icon={faHourglass} />
                        : null
                } */}
        {/*<CreateNewTaskModal setBlockedClickOutside={props.setBlockedClickOutside}/>*/}
        <NavButton
          text="Leave flow"
          icon={faSignOut}
          buttonProps={{
            onClick: () => {
              setIsModalOpened(true);
            },
          }}
        />
        {/*{props.userGrade === MemberOrganizationEnum.SUPER_ACTOR ? (*/}
        {/*  <NavButton*/}
        {/*    text="Update project float"*/}
        {/*    icon={faSignOut}*/}
        {/*    buttonProps={{*/}
        {/*      onClick: () => {*/}
        {/*        setIsFloatOpended(true);*/}
        {/*      },*/}
        {/*    }}*/}
        {/*  />*/}
        {/*) : null}*/}
        {props.userGrade === MemberOrganizationEnum.SUPER_ACTOR && (
            <NavButton
                text="Project member engagement"
                icon={faSignOut}
                buttonProps={{
                  onClick: () => {
                    setShowUsageModal(true);
                  },
                }}
            />
        )}
        {userIsProjectOwner && activeProject && activeProject.contractId && (
            <NavButton
                text="Refresh dashboard data"
                icon={faSignOut}
                buttonProps={{
                  onClick: () => {
                    fetch(`https://us-central1-flowledgerprod.cloudfunctions.net/create-bi-data/?contractId=${activeProject.contractId}&userId=${user.userId}`)
                        .catch(e => console.log(e))
                    alert("Data refresh request sent. Please wait a few minutes for the data to update. You will receive an e-mail when complete.")
                  },
                }}
            />
        )}
        {showUsageModal && (<UsageModal showUsageModal={showUsageModal} setShowUsageModal={setShowUsageModal} />)}
        {/*{props.userGrade === MemberOrganizationEnum.SUPER_ACTOR &&*/}
        {/*user?.enableSA ? (*/}
        {/*  <NavButton*/}
        {/*    text="Update target front factor"*/}
        {/*    icon={faSignOut}*/}
        {/*    buttonProps={{*/}
        {/*      onClick: () => {*/}
        {/*        setIsTargetFrontOpended(true);*/}
        {/*      },*/}
        {/*    }}*/}
        {/*  />*/}
        {/*) : null}*/}
      </div>
      {props.userGrade === MemberOrganizationEnum.SUPER_ACTOR &&
      projectMembersList
          // @ts-ignore
        .filter((member) => member.userId !== user!.userId)
        .every(
          (member) => member.grade !== MemberOrganizationEnum.SUPER_ACTOR
        ) ? (
        <NavBarCloseProjectModal
          opened={isModalOpened}
          projectId={props.project.projectId}
          setOpened={setIsModalOpened}
          leaveProject={props.leaveProject}
          nominatedMembersList={props.nominatedMembersList}
          setNominatedMembersList={props.setNominatedMembersList}
        />
      ) : (
        <NavBarLeaveProjectModal
          opened={isModalOpened}
          setOpened={setIsModalOpened}
          leaveProject={props.leaveProject}
        />
      )}
      <Modal
        closeIcon
        style={{ animationDuration: "1s" }}
        dimmer="blurring"
        className="modal-event"
        open={isFloatOpended}
        onClose={() => setIsFloatOpended(false)}
      >
        <Header textAlign="center" content={"Update project float"} />
        <div style={{ display: "flex", flexDirection: "column" }}>
          <Form.Input
            style={{ flexBasis: "50%", padding: "0 10px" }}
            value={floatValue}
            onChange={(_, data) => setFloatValue(data.value)}
            type="number"
          />
        </div>
        <Modal.Actions>
          <Button negative onClick={() => setIsFloatOpended(false)}>
            Cancel
          </Button>
          <Button
            disabled={!floatValue}
            positive
            onClick={() => updateProjectFloat()}
          >
            Update
          </Button>
        </Modal.Actions>
      </Modal>
      <Modal
        closeIcon
        style={{ animationDuration: "1s" }}
        dimmer="blurring"
        className="modal-event"
        open={isTargetFrontOpended}
        onClose={() => setIsTargetFrontOpended(false)}
      >
        <Header textAlign="center" content={"Update target front factor"} />
        <div style={{ display: "flex", flexDirection: "column" }}>
          <Form.Input
            style={{ flexBasis: "50%", padding: "0 10px" }}
            value={factorValue}
            onChange={(_, data) => setFactorValue(data.value)}
            type="number"
          />
        </div>
        <Modal.Actions>
          <Button negative onClick={() => setIsTargetFrontOpended(false)}>
            Cancel
          </Button>
          <Button
            disabled={!factorValue}
            positive
            onClick={() => updateTargetFrontFactor()}
          >
            Update
          </Button>
        </Modal.Actions>
      </Modal>
      <Modal
          closeIcon={status === "loading" ? false : true}
          size={"tiny"}
          style={{ animationDuration: "1s" }}
          dimmer="blurring"
          closeOnDimmerClick={status !== "loading"}
          open={isBaselineAssignOpen}
          onClose={() => setIsBaselineAssignOpen(false)}
      >
        <Header textAlign="center" content={"Assign a baseline file (XER)"} />
        {status === "loading" ?
            <Grid>
              <Grid.Column textAlign="center">
                <div style={{backgroundColor: "lightgray", padding: "15px"}}>
                  <Loader active inverted inline content='Loading' size="large"/>
                </div>
              </Grid.Column>
            </Grid>
                : status === "error" ?
                    <Grid>
                      <Grid.Column textAlign="center">
                        <Label color="red" size="large">
                          Error assigning baseline. Please contact your administrator for support.
                        </Label>
                      </Grid.Column>
                    </Grid>
                :
          <Modal.Content>
            <Grid>
              <Grid.Column style={{display: "flex", flexDirection: "column"}}>
                <Form.Input
                    style={{minWidth: "100%", margin: "auto"}}
                    value={baselinName}
                    placeholder="Choose baseline file name"
                    onChange={(_, data) => {
                      setBaselineName(data.value)
                      if (data.value.length < 3) {
                        setBaselineNameValid(false)
                        setBaselineNamePrompt("Baseline name must unique and contain at least 3 characters")
                        return
                      } else if (props.project) {
                        if (props.project.baselines && props.project.baselines.some((baseline) => baseline.baselineName === data.value)) {
                          setBaselineNameValid(false)
                          setBaselineNamePrompt("The name entered is not unique")
                          return;
                        }
                      }
                      setBaselineNameValid(true)
                      setBaselineNamePrompt("")
                    }}
                    type="text"
                />
                {!baselineNameValid ? <p style={{margin: "5px", padding: 0, maxWidth: "100%", fontSize: "12px"}}>{baselineNamePrompt}</p> : null}
                {/*<div className="file-input-div">*/}
                    <label className="file-input-div"
                           style={baselineFile === "Invalid file type" ? {backgroundColor: "#FCEAEC"}
                               : {backgroundColor: "white"}
                    }>
                      <input
                          onChange={(event) => {
                            event.preventDefault()
                            if (event.target.files) {
                              let file = event.target.files[0]
                              if (file && file.name.slice(file.name.length - 3) !== "xer") {
                                setBaselineFile("Invalid file type")
                              } else {
                                setBaselineFile(file ? file.name ? file.name : "No file selected" : "No file selected")
                                handleProjectContent(event.target.files[0].text())
                              }
                            }
                          }}
                          type="file"
                        />
                        <FontAwesomeIcon icon={faFilePlus} size="2xl"
                                             style={{
                                               maxWidth: "15px",
                                               padding: "3px 10px",}}/>
                          <span style={
                            baselineFile === "No file selected" ?
                                {padding: "8px 0px", color: "#dcdbdb"} :
                                baselineFile === "Invalid file type" ?
                                    {padding: "8px 0px", color: "red"} :
                            {padding: "8px 0px"}
                          }>
                            {baselineFile}</span>
                    </label>
                {/*</div>*/}
              </Grid.Column>
            </Grid>
          </Modal.Content>
        }
        <Modal.Actions>
          <Grid>
            <Grid.Column textAlign="center">
              <Button negative
                      disabled={status === "loading"}
                      onClick={() => {
                setIsBaselineAssignOpen(false)
                setBaselineName("")
                setBaselineFile("No file selected")
                setBaselineContent("")
              }}
                >
                Cancel
              </Button>
              <Button
                  disabled={baselineContent === "" || baselinName === "" || !baselineNameValid || status === "loading"}
                  positive
                  onClick={() => {
                    setStatus("loading")
                    try {
                      FirebaseProjectFunctions.assignBaselineToProject(
                          props.project.projectId,
                          baselineContent,
                          baselinName,
                          user ? user.userId ? user.userId : "none" : "none"
                      ).then((res: any) => {
                        if (res.data.response) {
                          setStatus("success")
                          setIsBaselineAssignOpen(false)
                          setBaselineName("")
                          setBaselineNamePrompt("Baseline name must unique and contain at least 3 characters")
                          setBaselineNameValid(false)
                          setBaselineFile("No file selected")
                          setBaselineContent("")
                          setShowManageBaselines(true)
                        } else {
                          setStatus("error")
                          setBaselineName("")
                          setBaselineNamePrompt("Baseline name must unique and contain at least 3 characters")
                          setBaselineNameValid(false)
                          setBaselineFile("No file selected")
                          setBaselineContent("")
                        }
                      })
                    } catch (e) {
                        setStatus("error")
                        console.log(e)
                    }
                  }}
              >
                Assign
              </Button>
            </Grid.Column>
          </Grid>
        </Modal.Actions>
      </Modal>
      {downloading &&
          <Dimmer active page={true}>
            <Loader size={"large"} inverted/>
          </Dimmer>}
      <NavBarDataDateModal
        opened={dataDateModalOpened}
        setOpened={setDataDateModalOpened}
        project={props.project} />
      {activeProject &&
      <ManageBaselineModal manageBaselines={showManageBaselines} setManageBaselines={setShowManageBaselines} activeProject={activeProject} />}
    </>
  );
};
