import { ActionsUnion, createAction, Maybe } from "@martin_hotell/rex-tils";
import TaskModel, {TaskSnapshot} from "../../models/responses/task.model";
import { TaskListSectionModel } from "../../models/task-list-section.model";
import { CheckList } from "../../models/checklist-model";
import { CalendarModel } from "../../models/responses/calendar.model";

export enum ActionNames {
  SET_TASK_LIST = "[Task] Set Task List",
  LOAD_MORE_TASK_LIST = "[Task] Load More Task List",

  SET_TASK_CHECKLIST = "[Task] Set Task Checklist",
  SET_ACTIVE_TASK_CHECKLIST = "[Task] Set Active Task Checklist",
  SET_ACTIVE_TASK = "[Task] Set Active Task",
  LIGHT_SELECTED_TASK = "[Task] Light selected Task",
  SET_TASK_LIST_LOADING = "[Task] Set Task List Loading",
  TASK_TOOLBAR_LOADING = "[Task] Task toolbar loading",
  TASK_TOOLBAR_SUCCESS = "[Task] Task toolbar success",
  TASK_TOOLBAR_FAIL = "[Task] Task toolbar fail",

  OPEN_RELATED_TASK_LIST = "[Task] Open Related Task List",
  SET_RELATED_TASK_LIST = "[Task] Set Related Task List",
  CLOSE_RELATED_TASK_LIST = "[Task] Close Related Task List",

  // Set Task list by Status
  SET_IN_PROGRESS_TASK_LIST = "[Task] Set In Progress Task List",
  SET_DECLARED_COMPLETE_TASK_LIST = "[Task] Set Declared Complete Task List",
  SET_CONFIRMED_COMPLETE_TASK_LIST = "[Task] Set Confirmed Complete Task List",
  SET_QUEUED_TASK_LIST = "[Task] Set Queued Task List",
  SET_PENDING_TASK_LIST = "[Task] Set Pending Task List",
  SET_TASK_LIST_COUNT = "[Task] Set Task List Count",

  SET_CALENDAR = "[Project] Set Calendar Data",

  // Debug values
  SET_DEBUG_CPM_TASKLIST = "[Task] Set Debug Cpm Task List",

  // Sort
  SORT_LIST = "[Task] Sort List",

  // Refresh Task List
  SET_REFRESH_TASK_LIST = "[Task] Set Refresh Task List",
  UNSET_REFRESH_TASK_LIST = "[Task] Unset Refresh Task List",
  SET_TASK_SNAPSHOT = "[Task] Set Task Snapshot",

}

export const Actions = {
  setTaskList: (taskList: TaskModel[]) =>
    createAction(ActionNames.SET_TASK_LIST, { taskList }),
  setActiveTaskChecklist: (activeChecklist: CheckList[]) =>
    createAction(ActionNames.SET_ACTIVE_TASK_CHECKLIST, { activeChecklist }),

  // Set Task List By Status
  setInProgressTaskList: (taskList: TaskModel[]) =>
    createAction(ActionNames.SET_IN_PROGRESS_TASK_LIST, { taskList }),
  setDeclaredCompleteTaskList: (taskList: TaskModel[]) =>
    createAction(ActionNames.SET_DECLARED_COMPLETE_TASK_LIST, { taskList }),
  setConfirmedCompleteTaskList: (taskList: TaskModel[]) =>
    createAction(ActionNames.SET_CONFIRMED_COMPLETE_TASK_LIST, { taskList }),
  setQueuedTaskList: (taskList: TaskModel[]) =>
    createAction(ActionNames.SET_QUEUED_TASK_LIST, { taskList }),
    setPendingTaskList: (taskList: TaskModel[]) =>
    createAction(ActionNames.SET_PENDING_TASK_LIST, { taskList }),
    setTaskListCount: (taskListCount: number) =>
    createAction(ActionNames.SET_TASK_LIST_COUNT, { taskListCount }),

  setCalendar: (calendar: CalendarModel | null) =>
    createAction(ActionNames.SET_CALENDAR, { calendar }),

  loadMoreTaskList: (page: number, taskListType: TaskListSectionModel) =>
    createAction(ActionNames.LOAD_MORE_TASK_LIST, { page, taskListType }),

  setActiveTask: (task: Maybe<TaskModel>) =>
    createAction(ActionNames.SET_ACTIVE_TASK, { task }),
  lightSelectedTask: (task: Maybe<TaskModel>) =>
    createAction(ActionNames.LIGHT_SELECTED_TASK, { task }),
  taskListLoading: () => createAction(ActionNames.SET_TASK_LIST_LOADING),
  taskToolbarLoading: (taskId: string) =>
    createAction(ActionNames.TASK_TOOLBAR_LOADING, { taskId }),
  taskToolbarSuccess: (taskId: string) =>
    createAction(ActionNames.TASK_TOOLBAR_SUCCESS, { taskId }),
  taskToolbarFail: (taskId: string) =>
    createAction(ActionNames.TASK_TOOLBAR_FAIL, { taskId }),
  setRefreshTaskList: (refreshTaskList: boolean) =>
    createAction(ActionNames.SET_REFRESH_TASK_LIST, { refreshTaskList }),
  unSetRefreshTaskList: (refreshTaskList: boolean) =>
    createAction(ActionNames.UNSET_REFRESH_TASK_LIST, { refreshTaskList }),
  setTaskSnapshot: (taskSnapshot: Map <string, TaskSnapshot>) =>
    createAction(ActionNames.SET_TASK_SNAPSHOT, { taskSnapshot }),

  openRelatedTaskList: (
    mainTask: TaskModel,
    type: "predecessors" | "successors"
  ) => createAction(ActionNames.OPEN_RELATED_TASK_LIST, { mainTask, type }),
  setRelatedTaskList: (
    list: TaskModel[],
    type: "predecessors" | "successors"
  ) => createAction(ActionNames.SET_RELATED_TASK_LIST, { list, type }),
  closeRelatedTaskList: (type: "predecessors" | "successors") =>
    createAction(ActionNames.CLOSE_RELATED_TASK_LIST, { type }),

  // Debug
  setDebugCpmTasklist: (tasklist: TaskModel[]) =>
    createAction(ActionNames.SET_DEBUG_CPM_TASKLIST, { tasklist }),

  // Sort
  setSortList: (taskList: {
    inProgress: TaskModel[];
    confirmedComplete: TaskModel[];
    declaredComplete: TaskModel[];
    queued: TaskModel[];
  }) => createAction(ActionNames.SORT_LIST, { taskList }),
};

export type Actions = ActionsUnion<typeof Actions>;
