import React, {useEffect, useState} from "react";
import "react-calendar/dist/Calendar.css";
import styles from "./HeaderCalendar.module.scss";
import { useActiveTaskSelector } from "../../../../../../store/selectors/task/task.selectors";
import { CustomCalendar } from "./components/CustomCalendar";
import { useTypedSelector } from "../../../../../../store/selectors/selectors.utils";
import classNames from "classnames";
import { TaskListSectionModel } from "../../../../../../models/task-list-section.model";

// import { getPredStatusClass } from "./calendar-utils/calendar.utils";

const HeaderCalendar: React.FC = () => {
  const activeTask = useActiveTaskSelector();
  const calendar = useTypedSelector((state) => state.task.calendar);
  const [schedule, setSchedule] = useState<boolean>(false);

  React.useEffect(() => {
    setSchedule(false);
  }, [activeTask]);

  useEffect(() => {
      const element = document.getElementById("calendar-wrapper");
      if (element) {
          element.scrollTop = element.scrollHeight;
      }
  }, [activeTask]);

  return (
    <>
      <div
        className={styles.HeaderCalendarDescription}
        style={calendar && activeTask ? { color: "#000" } : { border: "none" }}
      >
        <div className={classNames(styles.title)}>
          <div className={styles.schedule}>
            <p className="calendar-title">Schedule</p>
          </div>
          {calendar && activeTask && (
            <div
              className={styles.SwitchContainer}
              onClick={(e) => e.stopPropagation()}
              style={
                activeTask?.taskListType ===
                  TaskListSectionModel.CONFIRMED_COMPLETE ||
                activeTask?.taskListType ===
                  TaskListSectionModel.DECLARED_COMPLETE
                  ? { pointerEvents: "none", opacity: 0.5 }
                  : {}
              }
            >
              <span className={styles.SwitchTitle}>Earliest</span>
              <label className={styles.switch}>
                <input
                  type="checkbox"
                  checked={schedule}
                  onChange={() => {
                    setSchedule(!schedule);
                  }}
                />
                <span className={styles.slider}></span>
              </label>
              <span className={styles.SwitchTitle}>Latest</span>
            </div>
          )}
        </div>
      </div>
      <div className={styles.CalendarsWrapper} id={"calendar-wrapper"}>
        {calendar && activeTask && (
          <CustomCalendar activeTask={activeTask} schedule={schedule} />
        )}
      </div>
    </>
  );
};

export default React.memo(HeaderCalendar);
