import {CpmTaskModel} from "../../../../models/responses/cpm-task.model";
import {convertDateToIndex, convertIndexToSeconds} from "../../cpm-app/functions/handleEvent";
import TaskStatusModel from "../../../../models/responses/task-status.model";

export default function forecastTasks (
    racingLinePoints: any,
    incompleteTasks: CpmTaskModel[],
    calendarMap: Map<string, any>,
) {

    // console.log(racingLinePoints, incompleteTasks);

    const timeStart = new Date().getTime();
    let calendarForecast: any = {}
    let calendarExpected: any = {}
    let totalForecast = 0;
    for (let key in racingLinePoints) {
        let forecastProfile = new Map();
        let expectedMap = new Map();
        const points = racingLinePoints[key];
        if (points[points.length - 1][1] === 0) continue;
        for (let i = 0; i < points.length - 1; i++) {
            const point = points[i];
            const nextPoint = points[i + 1];
            const yDistance = nextPoint[1] - point[1];
            const pointIndex = convertDateToIndex(point[0], key, calendarMap);
            const nextPointIndex = convertDateToIndex(nextPoint[0], key, calendarMap);
            const xDistance = nextPointIndex - pointIndex;
            const yMultiplier = yDistance / xDistance;

            if (xDistance === 0) continue;

            for (let j = point[0]; j < nextPoint[0]; j += 86400) {
                const thisIndex = convertDateToIndex(j, key, calendarMap);
                const nextIndex = convertDateToIndex(j + 86400, key, calendarMap);
                if (thisIndex === nextIndex) continue;
                const y = Math.ceil(yMultiplier);
                expectedMap.set(j, y);
                totalForecast += y;
                forecastProfile.set(j, 0);
            }
        }
        const lastPoint = points[points.length - 1][0];
        if (!forecastProfile.has(lastPoint)) {
            forecastProfile.set(lastPoint, 0);
            expectedMap.set(lastPoint, 1);
        }

        calendarForecast[key] = forecastProfile;
        calendarExpected[key] = expectedMap;
    }

    // console.log("calendarForecast: ", calendarForecast);
    // console.log("calendarExpected: ", calendarExpected);

    // iterate through forecastProfile
    // @ts-ignore
    let numberDrawnIn = 0;
    for (let calKey in calendarForecast) {
        const forecastProfile = calendarForecast[calKey];
        const expectedMap = calendarExpected[calKey];
        for (const [key, value] of forecastProfile.entries()) {
            const nextKey = convertIndexToSeconds(convertDateToIndex(key + 86400, calKey, calendarMap), calKey, calendarMap);
            const nextY = expectedMap.get(key + 86400);
            const totalInPeriod = expectedMap.get(key);
            const remainingTaskToBeDrawnIn = totalInPeriod - value;
            // console.log(calKey, "remainingTaskToBeDrawnIn: ", remainingTaskToBeDrawnIn, "totalInPeriod: ", totalInPeriod, "value: ", value);
            if (remainingTaskToBeDrawnIn > 0) {
                let i = 0;
                for (const task of incompleteTasks) {
                    if (task.cal_id !== calKey) continue;
                    if (task.fs) continue;
                    if (i >= remainingTaskToBeDrawnIn && task.duration > 0) break;
                    if (task.status_code === TaskStatusModel.IN_PROGRESS && task.duration === 0) {
                        // console.log('doing this', task.task_code)
                        task.fs = task.es;
                        task.ff = task.ef;
                        continue;
                    }
                    const startIndex = convertDateToIndex(key, task.cal_id, calendarMap)
                    const endIndex = startIndex + task.duration - 1;

                    // @ts-ignore
                    const taskFsIndex = Math.max(startIndex, task.es)

                    // @ts-ignore
                    const taskFf = Math.ceil(convertIndexToSeconds(Math.max(endIndex, task.ef), task.cal_id, calendarMap) / 86400) * 86400;

                    // console.log(new Date(key * 1000).toString() ,"startIndex: ", startIndex, "TaskStartIndex: ", taskFsIndex, "taskFf: ", task.task_code);
                    if (taskFsIndex <= startIndex) {
                        // console.log('doing this', task.task_code)
                        if (task.duration > 0) {
                            i++;
                            task.fs = startIndex;
                            task.ff = endIndex
                        } else {
                            task.fs = startIndex;
                            task.ff = endIndex + 1;
                            continue;
                        }

                        for (let j = key; j < taskFf; j += 86400) {
                            const thisIndex = convertDateToIndex(j, calKey, calendarMap);
                            const nextIndex = convertDateToIndex(j + 86400, calKey, calendarMap);
                            if (Math.round((task.ff - nextIndex ) / (nextIndex - thisIndex)) > 0) {
                                if (forecastProfile.has(j)) {
                                    forecastProfile.set(j, forecastProfile.get(j) + 1);
                                }
                            }
                        }
                    }
                }
                if (i < remainingTaskToBeDrawnIn && nextY) {
                    expectedMap.set(nextKey, nextY + (remainingTaskToBeDrawnIn - i));
                }
                numberDrawnIn += i;
            }
        }
    }

    const timeEnd = new Date().getTime();
    console.log("forecastTasks time: ", timeEnd - timeStart, "ms");

    return incompleteTasks;
}