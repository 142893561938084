import TaskStatusModel from "../../../../models/responses/task-status.model";
import {CpmTaskModel} from "../../../../models/responses/cpm-task.model";

export default function checkRelationValidity(
    task: any,
    predecessorTask: any,
    relation: any
) {
    if (predecessorTask.status === TaskStatusModel.COMPLETE || predecessorTask.status === TaskStatusModel.DECLARED_COMPLETE ||
        task.status === TaskStatusModel.COMPLETE || task.status === TaskStatusModel.DECLARED_COMPLETE) {
        return false;
    }
    if (relation.pred_type === "FS") {
        if (predecessorTask.status !== TaskStatusModel.COMPLETE && predecessorTask.status !== TaskStatusModel.DECLARED_COMPLETE) {
            if (task.status !== TaskStatusModel.NOT_STARTED) {
                return false;
            } else {
                return true;
            }
        }
    } else if (relation.pred_type === "SS") {
        if (predecessorTask.status === TaskStatusModel.NOT_STARTED) {
            if (task.status !== TaskStatusModel.NOT_STARTED) {
                return false;
            } else {
                return true;
            }
        }
    }
    return true
}

export function checkCpmTaskLinkValidity(
    task: CpmTaskModel,
    predecessorTask: CpmTaskModel,
    relation: any
) {
    if (predecessorTask.status_code === TaskStatusModel.COMPLETE || predecessorTask.status_code === TaskStatusModel.DECLARED_COMPLETE ||
        task.status_code === TaskStatusModel.COMPLETE || task.status_code === TaskStatusModel.DECLARED_COMPLETE) {
        return false;
    }
    if (relation.pred_type === "FS") {
        // @ts-ignore
        if (predecessorTask.status_code !== TaskStatusModel.COMPLETE && predecessorTask.status_code !== TaskStatusModel.DECLARED_COMPLETE) {
            if (task.status_code !== TaskStatusModel.NOT_STARTED) {
                return false;
            } else {
                return true;
            }
        }
    } else if (relation.pred_type === "SS") {
        if (predecessorTask.status_code === TaskStatusModel.NOT_STARTED) {
            if (task.status_code !== TaskStatusModel.NOT_STARTED) {
                return false;
            } else {
                return true;
            }
        }
    }
    return true
}