import FirebaseUsage from "../firebase/firebase.usage";
import {TaskType} from "../models/task-type";
import TaskStatusModel from "../models/responses/task-status.model";
import {useUserSelector} from "../store/selectors/authorization.selectors";
import {getTaskListType} from "../store/thunk/transactions/utils/transactions.utils";
import {TaskListSectionModel} from "../models/task-list-section.model";
import {COLLECTIONS} from "../firebase/constants";
import LedgerEntry from "../models/responses/ledger-entry.model";
import {MessageType} from "../models/responses/message.model";
import {TaskPredType} from "../models/task-pred-type.enum";
import {useTaskCalendarSelector} from "../store/selectors/calendar.selectors";
import {useTypedSelector} from "../store/selectors/selectors.utils";
import CpmFunctionsController from "./cpm-functions/cpm-functions.controller";

export default async function addNewTask (taskInfo, projectId, user, calendars, calendarDict, combinedTasks) {

    let taskDoc = FirebaseUsage.getBlankDoc('tasks')
    let taskData = {
        task_name: taskInfo.task_name,
        task_id: taskDoc.id,
        task_type: taskInfo.task_type,
        task_code: taskInfo.task_code,
        taskCreatedTimestamp: FirebaseUsage.timestamp(),
        status: TaskStatusModel.NOT_STARTED,
        blocked: false,
        // NEED TO DO CALENDAR CONVERSION
        calendar_id: taskInfo.calendar_id,
        checklist: [],
        relations: taskInfo.predecessors.map(predecessor => {
            let relationshipDoc = FirebaseUsage.getBlankDoc(COLLECTIONS.RELATIONSHIPS)
            const data = {
                    pred_task_id: predecessor,
                    pred_type: TaskPredType.FS,
                    lag: 0,
                    task_id: taskDoc.id,
                    isValid: true,
                    project_id: projectId,
                    relationship_id: relationshipDoc.id,
                }
            let predTask = combinedTasks.find(task => task.task_id === predecessor)
            predTask.relations.push(data)
            FirebaseUsage.updateDoc(COLLECTIONS.TASKS, predecessor, {relations: predTask.relations}).catch(e => console.error(e))
            FirebaseUsage.setDocumentWithDoc(relationshipDoc, data).catch(e => console.error(e))
            return data
        }).concat(taskInfo.successors.map(successor => {
            let relationshipDoc = FirebaseUsage.getBlankDoc(COLLECTIONS.RELATIONSHIPS)
            const data = {
                    pred_task_id: taskDoc.id,
                    pred_type: TaskPredType.FS,
                    lag: 0,
                    task_id: successor,
                    isValid: true,
                    project_id: projectId,
                    relationship_id: relationshipDoc.id,
                }
            let succTask = combinedTasks.find(task => task.task_id === successor)
            succTask.relations.push(data)
            FirebaseUsage.updateDoc(COLLECTIONS.TASKS, successor, {relations: succTask.relations}).catch(e => console.error(e))
            FirebaseUsage.setDocumentWithDoc(relationshipDoc, data).catch(e => console.error(e))
            return data
        })),
        confirmedCompleteTimestamp: null,
        constraint_date: taskInfo.constraintDate,
        constraint_type: taskInfo.constraint ? taskInfo.constraint : '',
        act_start_date: null,
        act_end_date: null,
        early_start_date: null,
        early_end_date: null,
        late_start_date: null,
        late_end_date: null,
        declaredCompleteTimestamp: null,
        expiryDate: null,
        evaluated: false,
        enteredWorkInProcessTime: null,
        parent_wbs_name: taskInfo.parent_wbs_name ? taskInfo.parent_wbs_name : null,
        wbsPath: taskInfo.wbsPath,
        wbs: taskInfo.wbs_name,
        wbs_id: taskInfo.wbs_id,
        projectId: projectId,
        float: 0,
        flow: false,
        forecastDate: null,
        forecastFinish: null,
        predStatus: 2,
        taskForce: [user!.userId],
        suspended: false,
        targetDuration: parseInt(taskInfo.targetDuration),
        remainingDuration: parseInt(taskInfo.targetDuration),
        processedFrom: null,
        taskListType: TaskListSectionModel.PENDING,
    }

    if (taskInfo.wbs) {
        FirebaseUsage.updateDoc(COLLECTIONS.WORK_BREAKDOWN_STRUCTURE, taskInfo.wbs_id, {
            tasks: [...taskInfo.wbs.tasks, taskInfo.task_id]
        }).catch(e => console.error(e))
    }

    FirebaseUsage.setDocumentWithDoc(taskDoc, taskData)
        .then(() => CpmFunctionsController.addTask(taskData, projectId).catch(e => console.error(e)))
        .catch(e => console.error(e))

    let ledgerDoc = FirebaseUsage.getBlankDoc(COLLECTIONS.LEDGER_ENTRY)
    let ledgerData: LedgerEntry = {
        ledgerId: ledgerDoc.id,
        projectId: projectId,
        taskId: taskDoc.id,
        userEmail: user!.userEmail,
        timestamp: FirebaseUsage.timestamp(),
        logTimestamp: FirebaseUsage.timestamp(),
        type: MessageType.NEW,
        userId: user!.userId!,
    }
    FirebaseUsage.setDocumentWithDoc(ledgerDoc, ledgerData).catch(e => console.error(e))

    return "success"
}