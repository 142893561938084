import {ActionsUnion, createAction, Maybe} from "@martin_hotell/rex-tils";
import ProjectModel from "../../models/responses/project.model";
import { ProjectLoadingStage } from "../reducers/project";
import GeoModel from "../../models/responses/geo.model";

export enum ActionNames {
    SET_ACTIVE_PROJECT = '[Project] Set Active Project',
    SET_ACTIVE_PROJECT_ID = '[Project] Set Active Project Id',
    SET_PROJECT_LIST = '[Project] Set Project List',
    SET_ARCHIVE_PROJECT_LIST = '[Project] Set Archive Project List',
    SET_CPM_MAP = '[Project] Set CPM Map',
    SET_CPM_COMPLETE = '[Project] Set CPM Complete',

    SET_PROJECT_LOADING_STAGE = '[Project] Set Project Loading Stage',
    UPLOADING_NEW_PROJECT_STARTED = '[Project] Uploading New Project Started',
    UPLOADING_NEW_PROJECT_FAIL = '[Project] Uploading New Project Fail',
    UPLOADING_NEW_PROJECT_DETECT_STEP = '[Project] Uploading New Project Detect Steps',
    UPLOADING_NEW_PROJECT_FINISHED = '[Project] Uploading New Project Finished',
    SET_CALCULATIONS_IN_PROCESS = '[Project] Set Calculations In Process',
    SET_ACTIVE_PROJECT_CALENDARS = '[Project] Set Active Project Calendars',
    SET_ACTIVE_PROJECT_REASONS = '[Project] Set Active Project Reasons',
    SET_TRACKED_MILESTONES = '[Project] Set Tracked Milestones',
    SET_MONTE_CARLO_INDEX = '[Project] Set Monte Carlo Index',
    SET_VIEW = '[Project] Set View',
    SET_ACTIVE_PROJECT_QUALITY = '[Project] Set Active Project Quality',
    SET_GEO_DATA = '[Project] Set Geo Data',
    SET_ACTIVE_GEO = '[Project] Set Active Geo',
    SET_CHAT_LOG = '[Project] Set Chat Log',
    SET_CHAT_INITIALISED = '[Project] Set Chat Initialised',
    SET_VECTOR_STORE = '[Project] Set Vector Store',
    SET_LEDGER_OPEN = '[Project] Set Ledger Open',
    SET_CALENDAR_OPEN = '[Project] Set Calendar Open',
    SET_TEAM_OPEN = '[Project] Set Team Open',
    SET_MONTE_CARLO_RESULTS = '[Project] Set Monte Carlo Results',
}

export const Actions = {
    setActiveProject: (project: Maybe<ProjectModel>) => createAction(ActionNames.SET_ACTIVE_PROJECT, {project}),
    setActiveProjectId: (projectId: string | null | undefined) => createAction(ActionNames.SET_ACTIVE_PROJECT_ID, {projectId}),
    setActiveProjectCalendars: (calendars: Map<string, any>) => createAction(ActionNames.SET_ACTIVE_PROJECT_CALENDARS, {calendars}),
    setProjectList: (projectList: ProjectModel[]) => createAction(ActionNames.SET_PROJECT_LIST, {projectList}),
    setCpmMap: (cpmMap: Map<string, any>) => createAction(ActionNames.SET_CPM_MAP, {cpmMap}),
    setMonteCarloIndex: (monteCarloIndex: number) => createAction(ActionNames.SET_MONTE_CARLO_INDEX, {monteCarloIndex}),
    setTrackedMilestones: (trackedMilestones: any) => createAction(ActionNames.SET_TRACKED_MILESTONES, {trackedMilestones}),
    setCpmComplete: (cpmComplete: boolean) => createAction(ActionNames.SET_CPM_COMPLETE, {cpmComplete}),
    setArchiveProjectList: (archiveProjectList: ProjectModel[]) => createAction(ActionNames.SET_ARCHIVE_PROJECT_LIST, {archiveProjectList}),
    setCalculationsInProcess: (value: boolean) => createAction(ActionNames.SET_CALCULATIONS_IN_PROCESS, {value}),
    setProjectLoadingStage: (projectLoadingStage: ProjectLoadingStage) => createAction(ActionNames.SET_PROJECT_LOADING_STAGE, {projectLoadingStage}),
    uploadingNewProjectStarted: (totalWrites: number) => createAction(ActionNames.UPLOADING_NEW_PROJECT_STARTED, {totalWrites}),
    uploadingNewProjectDetectStep: (uploadedWrites: number) => createAction(ActionNames.UPLOADING_NEW_PROJECT_DETECT_STEP, {uploadedWrites}),
    uploadingNewProjectFinished: () => createAction(ActionNames.UPLOADING_NEW_PROJECT_FINISHED),
    uploadingNewProjectFail: (error: string) => createAction(ActionNames.UPLOADING_NEW_PROJECT_FAIL, {error}),
    setView: (view: string) => createAction(ActionNames.SET_VIEW, {view}),
    setActiveProjectQuality: (quality: string) => createAction(ActionNames.SET_ACTIVE_PROJECT_QUALITY, {quality}),
    setGeoData: (geoData: any) => createAction(ActionNames.SET_GEO_DATA, {geoData}),
    setActiveGeo: (geo: any) => createAction(ActionNames.SET_ACTIVE_GEO, {geo}),
    setActiveProjectReasons: (reasons: any) => createAction(ActionNames.SET_ACTIVE_PROJECT_REASONS, {reasons}),
    setChatLog: (chatLog: any) => createAction(ActionNames.SET_CHAT_LOG, {chatLog}),
    setChatInitialised: (value: boolean) => createAction(ActionNames.SET_CHAT_INITIALISED, {value}),
    setVectorStore: (vectorStore: any) => createAction(ActionNames.SET_VECTOR_STORE, {vectorStore}),
    setLedgerOpen: (value: boolean) => createAction(ActionNames.SET_LEDGER_OPEN, {value}),
    setCalendarOpen: (value: boolean) => createAction(ActionNames.SET_CALENDAR_OPEN, {value}),
    setTeamOpen: (value: boolean) => createAction(ActionNames.SET_TEAM_OPEN, {value}),
    setMonteCarloResults: (results: any) => createAction(ActionNames.SET_MONTE_CARLO_RESULTS, {results}),
};

export type Actions = ActionsUnion<typeof Actions>;
