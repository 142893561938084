import generateWBSPath from "./generateWBSPath";
import checkLinkValidity from "./checkLinkValidity";
import checkPredStatus from "./checkPredStatus";
import {getTaskListType} from "./getTaskListType";
import getWorkDays from "./getWorkDays";
import { v4 as uuid } from 'uuid';
import generateWBSId from "./generateWBSId";
import FirebaseUsage from "../../../firebase/firebase.usage";
import {COLLECTIONS, SUB_COLLECTIONS} from "../../../firebase/constants";
import TaskModel from "../../../models/responses/task.model";


export default async function uploadToFirestore (data, userId) {
    let batch: any[] = []
    let batchCount = 0

    let userMap = new Map()
    await FirebaseUsage.getCollection(COLLECTIONS.USERS)
        .then(snapshot => {
            snapshot.forEach(doc => {
                userMap.set(doc.data().userEmail, doc.id)
            })
        })

    let groupMap = new Map()
    await FirebaseUsage.getCollection(COLLECTIONS.GLOBAL_GROUPS)
        .then(snapshot => {
            snapshot.forEach(doc => {
                groupMap.set(doc.data().groupName, doc.id)
            })
        })

    const statusConverter = {
        "TK_Active": "in progress",
        "TK_Complete": "completed",
        "TK_NotStart": "not started",
    }

    const predTypeConverter = {
        "PR_FS": "FS",
        "PR_SS": "SS",
        "PR_FF": "FF",
        "PR_SF": "SF",
    }

    async function commitBatch (batchArray: any[]) {
        let batchObj = FirebaseUsage.batch()
        batchArray.forEach((doc) => {
            batchObj.set(doc.ref, doc.data)
        })
        await batchObj.commit()
    }

    console.log("doing projects")
    let projNames: any[] = []
    for (const [_, value] of data.projects.entries()) {
        console.log("project :", value.data.proj_id)
        const project = value.data
        const projectRef = value.docRef
        if (project.export_flag === 'Y') {
            // do relationships first

            console.log("doing relationships")
            await data.links.forEach((obj, id) => {
                const predTask = data.taskMap.get(obj.data.pred_task_id)
                const succTask = data.taskMap.get(obj.data.task_id)
                const link = obj.data

                if (link.proj_id !== project.proj_id) return
                if (data.projects.get(predTask.data.proj_id) && data.projects.get(predTask.data.proj_id).data.export_flag === 'N') return
                if (predTask.data.task_type === 'TT_WBS' || predTask.data.task_type === 'TT_LOE' ||
                    succTask.data.task_type === 'TT_WBS' || succTask.data.task_type === 'TT_LOE') return
                const isValid = checkLinkValidity(succTask.data, predTask.data, link)

                succTask.data.relations = succTask.data.relations ? [...succTask.data.relations, {
                    isValid: isValid,
                    lag: link.lag_hr_cnt * 2,
                    pred_type: predTypeConverter[link.pred_type],
                    pred_task_id: predTask.docRef.id,
                    task_id: succTask.docRef.id,
                }] : [{
                    isValid: isValid,
                    lag: link.lag_hr_cnt * 2,
                    pred_type: predTypeConverter[link.pred_type],
                    pred_task_id: predTask.docRef.id,
                    task_id: succTask.docRef.id,
                }]

                predTask.data.relations = predTask.data.relations ? [...predTask.data.relations, {
                    isValid: isValid,
                    lag: link.lag_hr_cnt * 2,
                    pred_type: predTypeConverter[link.pred_type],
                    pred_task_id: predTask.docRef.id,
                    task_id: succTask.docRef.id,
                }] : [{
                    isValid: isValid,
                    lag: link.lag_hr_cnt * 2,
                    pred_type: predTypeConverter[link.pred_type],
                    pred_task_id: predTask.docRef.id,
                    task_id: succTask.docRef.id,
                }]

                data.taskMap.set(predTask.data.task_id, predTask)
                data.taskMap.set(succTask.data.task_id, succTask)

                const linkRef = obj.docRef
                batch.push({
                    ref: linkRef,
                    data: {
                        lag: link.lag_hr_cnt * 2,
                        relationship_id: linkRef.id,
                        pred_task_id: predTask.docRef.id,
                        task_id: succTask.docRef.id,
                        project_id: data.projects.get(link.proj_id).docRef.id,
                        pred_type: predTypeConverter[link.pred_type],
                        isValid: isValid,
                    }
                })
                batchCount += 1
            })

            // do UDFs next
            await data.udfValue.forEach((obj, id) => {
                const udfType = data.udfType.get(obj.data.udf_type_id)
                if (udfType.data.udf_type_label === 'FL Checklist') {
                    let task = data.taskMap.get(obj.data.fk_id)
                    const checklist = obj.data.udf_text.split(',').map(checkListItem => {
                        const checklistItemName = checkListItem.trim()
                        return {
                            name: checklistItemName,
                            id: uuid(),
                            isChecked: false,
                        }
                    })
                    if (task) {
                        task.data.checklist = checklist
                        data.taskMap.set(task.data.task_id, task)
                    }
                } else if (udfType.data.udf_type_label === 'FL Task Force') {
                    let task = data.taskMap.get(obj.data.fk_id)
                    const taskForce = obj.data.udf_text.split(',').map(taskForceItem => {
                        let taskForceEmail = taskForceItem.trim()
                        const userId = userMap.get(taskForceEmail)
                        let groupId = groupMap.get(taskForceEmail)
                        if (!userId && !groupId) {
                            if (!taskForceEmail.includes('@')) {
                                taskForceEmail = '@' + taskForceEmail
                                groupId = groupMap.get(taskForceEmail)
                            }
                        }
                        return userId ? userId : groupId ? groupId : null
                    }).filter(userId => userId !== null)
                    if (task) {
                        task.data.taskForce = taskForce
                        data.taskMap.set(task.data.task_id, task)
                    }
                }
            })

            // upload tasks to firestore
            let earliestDate = null
            let latestDate = null
            let totalTasks = 0
            let inProgressTasks = 0
            let completedTasks = 0
            let tasksNotMilestones = 0

            console.log("doing tasks")
            await data.taskMap.forEach((obj, id) => {
                const task = obj.data
                if (task.proj_id !== project.proj_id) return
                if (task.task_type.toLowerCase() === 'tt_wbs' || task.task_type.toLowerCase() === 'tt_loe') return
                totalTasks += 1
                if (task.status_code === 'TK_Active') inProgressTasks += 1
                if (task.status_code === 'TK_Complete') completedTasks += 1
                if (task.task_type.toLowerCase() === 'tt_task' ||  task.task_type.toLowerCase() === 'tt_rsrc') tasksNotMilestones += 1
                if (task.act_start_date) {
                    if (earliestDate && task.act_start_date.seconds < earliestDate!) {
                        earliestDate = task.act_start_date.seconds
                    } else if (!earliestDate) {
                        earliestDate = task.act_start_date.seconds
                    }
                }
                if (task.early_start_date) {
                    if (earliestDate && task.early_start_date.seconds < earliestDate!) {
                        earliestDate = task.early_start_date.seconds
                    } else if (!earliestDate) {
                        earliestDate = task.early_start_date.seconds
                    }
                }
                if (task.act_end_date) {
                    if (latestDate && task.act_end_date.seconds > latestDate!) {
                        latestDate = task.act_end_date.seconds
                    } else if (!latestDate) {
                        latestDate = task.act_end_date.seconds
                    }
                }
                if (task.late_end_date) {
                    if (latestDate && task.late_end_date.seconds > latestDate!) {
                        latestDate = task.late_end_date.seconds
                    } else if (!latestDate) {
                        latestDate = task.late_end_date.seconds
                    }
                }
                const taskRef = obj.docRef
                const wbs = data.wbsMap.get(task.wbs_id)
                const parentWBS = data.wbsMap.get(wbs.data.parent_wbs_id)
                const wbsPath = generateWBSPath(data.wbsMap, task.wbs_id)
                const wbsTasks = wbs.data.tasks ? [...wbs.data.tasks, taskRef.id] : [taskRef.id]
                data.wbsMap.set(wbs.data.wbs_id, {...wbs, data: {...wbs.data, tasks: wbsTasks}})
                // if (parentWBS && parentWBS.data.children) {
                //     data.wbsMap.set(parentWBS.data.wbs_id, {...parentWBS,
                //         data: {...parentWBS.data,
                //             children: [...parentWBS.data.children, wbs.data.wbs_id]}})
                // } else  if (parentWBS) {
                //     data.wbsMap.set(parentWBS.data.wbs_id, {...parentWBS,
                //         data: {...parentWBS.data,
                //             children: [wbs.data.wbs_id]}})
                // }

                // add tracked milestones
                if (task.cstr_date !== null) {
                    if (task.cstr_type === 'CS_MEO' || task.cstr_type === 'CS_MEOB') {
                        const milestoneRef = FirebaseUsage.getBlankDoc(COLLECTIONS.TRACKED_MILESTONES)
                        batch.push({ref: milestoneRef,
                            data: {
                                projectId: projectRef.id,
                                milestoneId: milestoneRef.id,
                                milestoneName: task.task_name,
                                constraintType: task.cstr_type,
                                constraintDate: task.cstr_date,
                                baselineDate: task.cstr_date,
                                visibleToAll: true,
                                hidden: false,
                                taskCode: task.task_code,
                                userId: userId,
                                wbsName: wbs.data.wbs_name,
                                taskId: taskRef.id,
                            }
                        })
                    }
                }

                let taskData: TaskModel = {
                    task_name: task.task_name,
                    task_id: taskRef.id,
                    task_type: task.task_type,
                    task_code: task.task_code,
                    status: statusConverter[task.status_code],
                    blocked: false,
                    calendar_id: task.clndr_id,
                    checklist: [],
                    importedChecklist: task.checklist ? task.checklist : [],
                    relations: task.relations ? task.relations : [],
                    confirmedCompleteTimestamp: task.act_end_date ? task.act_end_date : null,
                    constraint_date: task.cstr_date,
                    constraint_type: task.cstr_type,
                    act_start_date: task.act_start_date ? task.act_start_date : null,
                    act_end_date: task.act_end_date ? task.act_end_date : null,
                    early_start_date: task.early_start_date ? task.early_start_date : null,
                    early_end_date: task.early_end_date ? task.early_end_date : null,
                    late_start_date: task.late_start_date ? task.late_start_date : null,
                    late_end_date: task.late_end_date ? task.late_end_date : null,
                    declaredCompleteTimestamp: task.act_end_date ? task.act_end_date : null,
                    expiryDate: task.status_code === 'TK_Active' ? task.target_end_date ? task.target_end_date : FirebaseUsage.timestamp() : null,
                    evaluated: false,
                    enteredWorkInProcessTime: null,
                    parent_wbs_name: parentWBS ? data.wbsMap.get(parentWBS.data.parent_wbs_id) ? {
                        parent_wbs_id: data.wbsMap.get(parentWBS.data.parent_wbs_id).docRef.id,
                        wbs_id: parentWBS.docRef.id,
                        wbs_name: parentWBS.data.wbs_name,
                    } : null : null,
                    wbsPath: parentWBS ? wbsPath.wbsPath : wbs.data.wbs_name,
                    wbs: wbs.data.wbs_name,
                    wbs_id: wbs.docRef.id,
                    projectId: data.projects.get(task.proj_id).docRef.id,
                    float: task.total_float_hr_cnt * 2,
                    flow: true,
                    forecastDate: task.target_start_date ? task.target_start_date : null,
                    forecastFinish: task.target_end_date ? task.target_end_date : null,
                    predStatus: checkPredStatus(task, data.taskMap),
                    taskForce: task.taskForce ? task.taskForce : [],
                    suspended: false,
                    targetDuration: task.target_drtn_hr_cnt * 2,
                    remainingDuration: task.remain_drtn_hr_cnt * 2,
                    processedFrom: null,
                    taskListType: getTaskListType(task.status_code),
                }

                if (taskData.expiryDate) {
                    taskData.targetFinishDate = taskData.expiryDate
                }

                batch.push({
                    ref: taskRef,
                    data: taskData
                })
                batchCount += 1
            })

            console.log("doing wbs")
            // upload wbs to firestore
            let projName = 'Placeholder name - error if shown'
            await data.wbsMap.forEach((obj, id) => {
                const wbs = obj.data
                if (wbs.proj_id !== project.proj_id) return
                if (wbs.proj_node_flag === 'Y') projName = wbs.wbs_name
                const parentWBS = data.wbsMap.get(wbs.parent_wbs_id)
                if (parentWBS) {
                    data.wbsMap.set(parentWBS.data.wbs_id, {...parentWBS,
                        data: {...parentWBS.data,
                            children: parentWBS.data.children ? [...parentWBS.data.children, obj.docRef.id] :
                                [obj.docRef.id]
                        }
                    })
                }
                const wbsInfo = generateWBSId(data.wbsMap, wbs.wbs_id)
                data.wbsMap.set(wbs.wbs_id, {...obj, data: {...obj.data,
                        wbs_code: wbsInfo.wbsId,
                        level: wbsInfo.level,
                }})
            })
            await data.wbsMap.forEach((obj, id) => {
                const wbs = obj.data
                const wbsRef = obj.docRef
                if (wbs.proj_id !== project.proj_id) return
                const parentWBS = data.wbsMap.get(wbs.parent_wbs_id)
                batch.push({
                    ref: wbsRef,
                    data: {
                        wbs_id: wbsRef.id,
                        wbs_name: wbs.wbs_name,
                        wbs_short_name: wbs.wbs_short_name,
                        wbs_code: wbs.wbs_code ? wbs.wbs_code : '',
                        level: wbs.level ? wbs.level : 0,
                        children: wbs.children ? wbs.children : [],
                        parent_wbs_id: parentWBS ? parentWBS.docRef.id : null,
                        projectId: projectRef.id,
                        tasks: wbs.tasks ? wbs.tasks : [],
                    }
                })
                batchCount += 1
            })

            // upload calendars to firestore
            let order = 0
            let targetFronts = {}
            await data.calendars.forEach((obj, id) => {
                if (obj.data.clndr_type && obj.data.clndr_type.toLowerCase() === 'ca_rsrc') return
                const calendar = obj.data
                const calendarRef = obj.docRef
                targetFronts = {...targetFronts, [calendarRef.id]: 0}
                console.log("calendar: ", id, calendar.clndr_name)
                const workdayPattern = getWorkDays(calendar.clndr_data)
                const calendarData = {
                    uId: calendarRef.id,
                    calendar_id: calendar.clndr_id,
                    calendar_name: calendar.clndr_name,
                    project_id: projectRef.id,
                    workday_exceptions: workdayPattern.exceptionsList,
                    working_days_of_the_week: workdayPattern.workDayList,
                    working_hours_per_day: calendar.day_hr_cnt ? calendar.day_hr_cnt : 8,
                }

                const calendarDataStr = JSON.stringify(calendarData)
                const calendarSubCollectionRef = FirebaseUsage.getBlankDocFromSubCollection(COLLECTIONS.PROJECTS, projectRef.id, SUB_COLLECTIONS.CALENDARS)
                batch.push({
                    ref: calendarSubCollectionRef,
                    data: {calendars: calendarDataStr, order: order, id: calendarSubCollectionRef.id}
                })
                batchCount += 1

                batch.push({
                    ref: calendarRef,
                    data: calendarData
                })
                batchCount += 1
                order += 1
            })
            // go through the batch in chunks of 500 and commit
            console.log("batchCount: ", batchCount)
            const sendBatch = async (batch: any[], count: number) => {
                if (count < 500) {
                    await commitBatch(batch)
                } else {
                    console.log("committing batches")
                    for (let i = 0; i < Math.ceil(count / 500); i++) {
                        console.log("committing batch: ", i + 1)
                        await commitBatch(batch.slice(i * 500, Math.min(batch.length, (i * 500) + 500)))
                    }
                }
            }
            await sendBatch(batch, batchCount)

            // set the project data
            const storageDoc = FirebaseUsage.getBlankDoc(COLLECTIONS.PROJECT_STORAGE)
            await FirebaseUsage.setDocumentWithDoc(storageDoc,{
                projectId: projectRef.id,
                storageId: storageDoc.id,
                storageBucketId: uuid(),
            });

            await FirebaseUsage.setDocumentWithDoc(projectRef, {
                archived: false,
                blocked: {},
                xerBucketFilename: projNames.length > 0 ? projNames[0][1] : projectRef.id,
                completedTaskCount: completedTasks,
                debugData: '',
                disabled: false,
                targetFronts: targetFronts,
                issueCategories: [
                    {reason: 'Directive', text: 'Directive'},
                    {reason: 'Incident', text: 'Incident'},
                    {reason: 'Information', text: 'Information'},
                    {reason: 'Resource', text: 'Resource'},
                    {reason: 'Other', text: 'Other'},
                ],
                earliestDate: earliestDate ? earliestDate * 1000 : new Date().getTime(),
                latestDate: latestDate ? latestDate * 1000 : new Date().getTime(),
                grade: {[userId]: 'Super-Actor'},
                lastUpdate: FirebaseUsage.timestamp(),
                name: projName,
                suspended: {},
                storageId: storageDoc.id,
                projectId: projectRef.id,
                tasksInProgress: inProgressTasks,
                tasksNotMilestonesCount: tasksNotMilestones,
                totalTaskCount: totalTasks,
                updateDate: FirebaseUsage.timestamp(),
                updateState: 'done',
                uploadState: 'pending',
                wasStarted: true,
            })

            const user = await FirebaseUsage.getDoc(COLLECTIONS.USERS, userId)
                .then(doc => doc.data())

            // set the project member:
            const projectMemberRef = FirebaseUsage.getBlankDoc(COLLECTIONS.PROJECT_MEMBERS)
            await FirebaseUsage.setDocumentWithDoc(projectMemberRef, {
                projectId: projectRef.id,
                memberId: projectMemberRef.id,
                userId: userId,
                userEmail: user!.userEmail,
                grade: 'Super-Actor',
                gateKeeper: "None ",
                is_admin: user!.isAdmin,
                is_ledger: false,
                is_pending: false,
                tasks: 0,
            })
            console.log("setting project member")

            await FirebaseUsage.updateDoc(COLLECTIONS.USERS, userId, {
                asMember: {...user!.asMember, [projectRef.id]: {
                    grade: 'Super-Actor',
                    memberId: projectMemberRef.id,
                    projectId: projectRef.id,
                }}
            })
            projNames.push([projName, projectRef.id])
        }
    }
    return projNames
}