import React, {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import {Button, Form, Input} from "semantic-ui-react";
import { useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import * as searchActions from "../../../../store/actions/search.actions";
import { SearchActions } from "../../../../store/actions/search.actions";
import {
    useIncludeMilestonesSelector,
    useSearchLoadingSelector,
    useSearchParamatersSelector,
} from "../../../../store/selectors/search.selectors";
import { ChipsList } from "./components/ChipsList";
import styles from "./SearchBar.module.scss";
import classNames from "classnames";
import { ReportDropdown } from "./components/ReportsDropdown";
import { useUserGradeSelector, useUserSelector } from "../../../../store/selectors/authorization.selectors";
import { debounce } from "../../../../utils/debounce";
import { SearchThunk } from "../../../../store/thunk/search.thunk";
import { useTypedSelector } from "../../../../store/selectors/selectors.utils";
import * as taskActions from "../../../../store/actions/task.actions";
import * as projectActions from "../../../../store/actions/project.actions";
import {
  usePredeccessorsViewSelector,
  useSuccessorsViewSelector,
} from "../../../../store/selectors/task/task.selectors";
import {useActiveProjectSelector, useProjectViewSelector} from "../../../../store/selectors/project.selectors";
import { useActiveTaskSelector } from "../../../../store/selectors/task/task.selectors";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCalendar,
  faCommentsAlt,
  faUsers,
  faTimes,
  faExclamation,
  faHandshakeAlt,
  faSearch, faPlus, faChartPie, faMapLocationDot, faChartGantt, faPrint, faDiamond, faUsersSlash,
    faPersonRunningFast, faReflectHorizontal
} from "@fortawesome/pro-light-svg-icons";
import NewTaskModal from "../../pages/FlowPage/components/NewTaskModal";
import { MemberOrganizationEnum } from "../../../../models/member-organization.enum";
import store from "../../../../store/store";
import {userHasSeen} from "../../../../utils/taskforce.utils";
import DatePicker from "react-datepicker";
import OnlineUsers from "./components/OnlineUsers";

interface InjectedProps {
  disabled: boolean;
  openSpecificAdditional: (
    type: "ledger" | "member" | "profile" | "calendar", open: boolean
  ) => void;
  isOpenedLedger: boolean;
  isOpenedMember: boolean;
  isOpenedProfile: boolean;
  isOpenedCalendar: boolean;
  isLarge: boolean;
  setLargeMode: Dispatch<SetStateAction<boolean>>;
}

export const SearchBar: React.FC<InjectedProps> = (props) => {
  const [hasToggled, setHasToggled] = useState(false);
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const myTasksParam = searchParams.get("myTasks");
  const userGrade = useUserGradeSelector();
  const activeProject = useActiveProjectSelector();
  const activeTask = useActiveTaskSelector();
  const lightSelectedTask = useTypedSelector(
    (state) => state.task.lightSelectedTask
  );
  const selectedTask = useMemo(
    () => lightSelectedTask || activeTask,
    [lightSelectedTask, activeTask]
  );

  const searchBarLoading = useSearchLoadingSelector();
  const dispatch = useDispatch();
  const hasProjectBeenSelected = useTypedSelector((state) =>
    Boolean(state.project.activeProject)
  );
  const searchInputRef = useRef<any>(null);
  const searchParameters = useSearchParamatersSelector();
  const user = useUserSelector();
  const [expandedSearchBar, setExpandedSearchBar] = useState(false);
  const [newTaskModalOpen, setNewTaskModalOpen] = useState(false);
  const predecessorsView = usePredeccessorsViewSelector();
  const successorsView = useSuccessorsViewSelector();
  const showMilestones = useIncludeMilestonesSelector();
  const projectView = useProjectViewSelector();
  const [quality, setQuality] = useState(false);
  const [map, setMap] = useState(false);
  const [gannt, setGannt] = useState(false);
  const [hoverButton, setHoverButton] = useState<any>(null);
  const [dateFilterOpen, setDateFilterOpen] = useState(false);
  const view = useMemo(() => {
    if (predecessorsView) {
      return "predecessors";
    } else {
      return "successors";
    }
  }, [predecessorsView]);
  const globalView = useProjectViewSelector();

  const handleChangeSearchValue = useCallback(
    debounce((value) => {
      if (value.length >= 3) {
        // @ts-ignore
        dispatch(SearchThunk.setSearchValue(value));
        dispatch(SearchActions.setIncludeMilestones(true));
      } else {
        // @ts-ignore
        dispatch(SearchThunk.setSearchValue(""));
      }
    }, 500),
    [dispatch]
  );

    useEffect(() => {
        if (projectView === "task" && (quality || map || gannt)) {
            setQuality(false);
            setMap(false);
            setGannt(false);
        }
    }, [projectView]);

  const closeView = useCallback(
    (view: "successors" | "predecessors") => {
      // props.setIsSearchBarDisabled(false);
      dispatch(taskActions.Actions.closeRelatedTaskList(view));
    },
    [dispatch]
  );

  const addChip = useCallback(async () => {
    if (searchInputRef && searchInputRef.current) {
      const value = searchInputRef.current.inputRef.current.value;
      if (value.length >= 3) {
        setExpandedSearchBar(true);
        dispatch(SearchActions.addChip([{ type: "filter", text: value }]));
        await handleChangeSearchValue("");
        searchInputRef.current.inputRef.current.value = "";
        setExpandedSearchBar(false);
        searchInputRef.current.inputRef.current.blur();
      } else if (value.length === 0) {
        setExpandedSearchBar(!expandedSearchBar);
        await handleChangeSearchValue("");
        searchInputRef.current.inputRef.current.value = "";
      }
    }
  }, [handleChangeSearchValue, dispatch, expandedSearchBar, searchInputRef]);

  const toggleOnlyMyTasks = useCallback(() => {
    setHoverButton(null)
    if (!user) {
      return;
    }
    if (searchParameters.taskForces.includes(user.userId)) {
      dispatch(
        searchActions.SearchActions.removeIdFromTaskforecFilter([user.userId])
      );
    } else {
        dispatch(searchActions.SearchActions.setTaskforceFilter([user.userId]));
    }
  }, [searchParameters, user, dispatch]);

 useEffect(() => {
   if (!hasToggled && activeProject && user) {
     const taskList = store
       .getState()
       .task.tasks.queued.concat(store.getState().task.tasks.inProgress)
       .concat(store.getState().task.tasks.declaredComplete)
       .concat(store.getState().task.tasks.confirmedComplete);
     const filteredTaskList = taskList.filter((task) => {
       return task.taskForce.includes(user.userId);
     });
     if (
       myTasksParam ||
       (!userGrade[MemberOrganizationEnum.SUPER_ACTOR] &&
         filteredTaskList.length > 0)
     ) {
       toggleOnlyMyTasks();
       setHasToggled(true);
     }
   }
 }, [activeProject, user, myTasksParam, userGrade, hasToggled, setHasToggled]);

  const updateUserTaskSeenHandler = useCallback(() => {
    if (user && activeTask && activeTask!.taskForce.includes(user.userId)) {
        userHasSeen(user!.userId!, activeTask).catch((e) => console.error(e));
    }
  }, [activeTask, dispatch, user]);

  const taskId = useMemo(() => activeTask?.task_id, [activeTask]);
  const taskListType = useMemo(() => activeTask?.taskListType, [activeTask]);

  useEffect(() => {
    if (props.isOpenedLedger && taskId && user && activeTask && activeTask!.taskForce.includes(user.userId)) {
        userHasSeen(user!.userId!, activeTask!).catch((e) => console.error(e));
    }
  }, [dispatch, taskId, taskListType, props.isOpenedLedger, user]);

  const wbsAdressNames = useMemo(() => {
    const wbsAddress = selectedTask ? selectedTask.wbsPath : "WBS Path:";
    return wbsAddress;
  }, [selectedTask]);

  const newWbsAddress: string = useMemo(() => {
    return wbsAdressNames
  }, [wbsAdressNames, activeProject]);

  if (!user) {
    return null;
  }

  function handleHoverButton(text: string) {
      const getParams = (id: string) => {
          return document.getElementById(id)?.getBoundingClientRect();
      }

      const setParams = (text: string, params: any) => {
          const width = text.length * 6.8;
          const screen = window.innerWidth;
          let x = params.x - (width / 2) + (params.width / 2);
            if (x < 0) {
                x = 0;
            } else if (x + width > screen) {
                x = screen - width;
            }
          setHoverButton({
                x: x,
                y: params.y + 38,
                text: text
          })
      }

      let params: any
      switch (text) {
          case "Tasks with alerts":
            params = getParams("alert");
            setParams("Tasks with alerts", params);
            break;
          case "My tasks":
            params = getParams("myTasks");
            setParams("My tasks", params);
            break;
          case "Tasks with no task force":
            params = getParams("noTaskForce");
            setParams("Tasks with no task force", params);
            break;
          case "Add new task":
            params = getParams("addTask");
            setParams("Add new task", params);
            break;
          case "Show milestones":
            params = getParams("milestones");
            setParams("Show milestones", params);
            break;
          case "Search tasks":
            params = getParams("search");
            setParams("Search tasks", params);
            break;
          case "Apply time filter":
            params = getParams("timeFilter");
            setParams("Apply time filter", params);
            break;
          case "Print Gantt chart":
            params = getParams("printGantt");
            setParams("Print Gantt chart", params);
            break;
          case "Gantt view":
            params = getParams("gantt");
            setParams("Gantt view", params);
            break;
          case "Open map":
            params = getParams("map-button");
            setParams("Open map", params);
            break;
          case "Quality view":
            params = getParams("quality");
            setParams("Quality view", params);
            break;
          case "Task calendar":
            params = getParams("calendar");
            setParams("Task calendar", params);
            break;
          case "Task ledger":
            params = getParams("ledger");
            setParams("Task ledger", params);
            break;
          case "Project team":
            params = getParams("member");
            setParams("Project team", params);
            break;
          case "Sprint view":
            params = getParams("sprint");
            setParams("Sprint view", params);
            break;
          case "Change log":
            params = getParams("change");
            setParams("Change log", params);
      }
  }

  return (
      <>
          <div className={styles.Container}>
              <div className={styles.Wrapper}>
                  <div className={styles.Tools}>
                      <div className={styles.FilterItem}>
                          <Button
                              id={"alert"}
                              circular
                              size="tiny"
                              disabled={!hasProjectBeenSelected}
                              className={classNames([
                                  styles.FilterButton,
                                  {[styles.ActiveFilterButton]: searchParameters.alert},
                              ])}
                              onClick={() => {
                                  setHoverButton(null)
                                  dispatch(SearchActions.toggleAlert())
                              }}
                              onMouseEnter={() => handleHoverButton("Tasks with alerts")}
                              onMouseLeave={() => setHoverButton(null)}
                          >
                              <FontAwesomeIcon icon={faExclamation}/>
                          </Button>
                      </div>
                      <div className={styles.FilterItem}>
                          <Button
                              id={"myTasks"}
                              disabled={!hasProjectBeenSelected}
                              className={classNames([
                                  styles.FilterButton,
                                  {
                                      [styles.ActiveFilterButton]:
                                          searchParameters.taskForces.includes(user.userId),
                                  },
                              ])}
                              onClick={toggleOnlyMyTasks}
                              onMouseEnter={() => handleHoverButton("My tasks")}
                              onMouseLeave={() => setHoverButton(null)}
                              circular
                              size="tiny"
                          >
                              <FontAwesomeIcon icon={faHandshakeAlt}/>
                          </Button>
                      </div>
                      <div className={styles.FilterItem}>
                          <Button
                              id={"noTaskForce"}
                              disabled={!hasProjectBeenSelected}
                              className={classNames([
                                  styles.FilterButton,
                                  {
                                      [styles.ActiveFilterButton]:
                                          searchParameters.noTaskForce,
                                  },
                              ])}
                              onClick={() => {
                                  setHoverButton(null)
                                  dispatch(SearchActions.setNoTaskForceFilter(!searchParameters.noTaskForce))
                              }}
                              onMouseEnter={() => handleHoverButton("Tasks with no task force")}
                              onMouseLeave={() => setHoverButton(null)}
                              circular
                              size="tiny"
                          >
                              <FontAwesomeIcon icon={faUsersSlash}/>
                          </Button>
                      </div>
                      <div className={styles.FilterItem}>
                          <ReportDropdown/>
                      </div>
                      <div className={styles.FilterItem}>
                          <Button circular size="tiny"
                                  id={"addTask"}
                              // disabled={!activeProject || !activeProject.projectId}
                                  disabled={true}
                                  className={styles.AddButton}
                                  onClick={() => {
                                      setHoverButton(null)
                                      setNewTaskModalOpen(true)
                                  }}
                                  onMouseEnter={() => handleHoverButton("Add new task")}
                                  onMouseLeave={() => setHoverButton(null)}
                          >
                              <FontAwesomeIcon icon={faPlus}/>
                          </Button>
                      </div>
                      <div className={styles.FilterItem}>
                          <Button circular size="tiny"
                                  id={"milestones"}
                                  disabled={!activeProject || !activeProject.projectId}
                              // style={!showMilestones && {backgroundColor: "white"}}
                                  active={showMilestones}
                                  className={classNames([
                                      styles.FilterButton,
                                      {
                                          [styles.ActiveFilterButton]:
                                          showMilestones,
                                      },
                                  ])}
                                  onClick={() => {
                                      setHoverButton(null)
                                      dispatch(searchActions.SearchActions.setIncludeMilestones(!showMilestones))
                                  }}
                                  onMouseEnter={() => handleHoverButton("Show milestones")}
                                  onMouseLeave={() => setHoverButton(null)}
                          >
                              <FontAwesomeIcon icon={faDiamond}/>
                          </Button>
                      </div>
                      {/*<div className={styles.FilterItem}>*/}
                      {/*  <Button circular size="tiny"*/}
                      {/*          disabled={!activeProject || !activeProject.projectId}*/}
                      {/*          style={{fontFamily: "Arial"}}*/}
                      {/*          // className={styles.AddButton}*/}
                      {/*          className={classNames([*/}
                      {/*            // styles.AddButton,*/}
                      {/*            styles.FilterButton,*/}
                      {/*            {[styles.QualityButtonBackground]: !quality},*/}
                      {/*            {*/}
                      {/*              [styles.ActiveFilterButton]: quality,*/}
                      {/*            },*/}
                      {/*          ])}*/}
                      {/*          onClick={() => {*/}
                      {/*              setQuality(!quality)*/}
                      {/*              dispatch(projectActions.Actions.setView(quality ? "task" : "quality"))*/}
                      {/*            }*/}
                      {/*          }>*/}
                      {/*    Q*/}
                      {/*  </Button>*/}
                      {/*</div>*/}
                      {!dateFilterOpen && <div
                          id={"search"}
                          style={{marginRight: "0px"}}
                          className={classNames(styles.FilterItem, styles.SearchBar, {
                              [styles.Expanded]: expandedSearchBar,
                          })}
                      >
                          <Form onSubmit={addChip}
                                onMouseEnter={() => handleHoverButton("Search tasks")}
                                onMouseLeave={() => setHoverButton(null)}
                          >
                              <Input
                                  disabled={!hasProjectBeenSelected}
                                  className={classNames(styles.InputWrapper, {
                                      [styles.ActiveFilterSearchInput]:
                                      (searchParameters.searchText ||
                                          searchParameters.chipList.length) &&
                                      expandedSearchBar,
                                  })}
                                  size="small"
                                  actionPosition="left"
                                  ref={searchInputRef}
                                  action={{
                                      icon: <FontAwesomeIcon icon={faSearch}/>,
                                      loading: searchBarLoading,
                                      disabled: !hasProjectBeenSelected,
                                      className: classNames({
                                          [styles.ActiveFilterSearchButton]:
                                          searchParameters.searchText ||
                                          searchParameters.chipList.length,
                                      }),
                                  }}
                                  placeholder="Search..."
                                  onChange={async (evt) => {
                                      dispatch(SearchActions.setSearchBarLoading(true));
                                      await handleChangeSearchValue(evt.target.value);
                                  }}
                              />
                          </Form>
                          <div className={styles.CalendarSearch}
                               id={"timeFilter"}
                               onClick={() => {
                                   setHoverButton(null)
                                   setDateFilterOpen(prevState => !prevState)
                               }}
                               onMouseEnter={() => handleHoverButton("Apply time filter")}
                               onMouseLeave={() => setHoverButton(null)}
                          >
                              <FontAwesomeIcon
                                  className={styles.CalendarSearchButton}
                                  icon={faCalendar}/>
                          </div>
                      </div>}
                      {dateFilterOpen &&
                          <div className={styles.DateRangeFilter}>
                              <div
                                  style={{marginRight: "0px", marginTop: "10px"}}
                                  className={classNames(styles.FilterItem, styles.SearchBar, {
                                      [styles.Expanded]: expandedSearchBar,
                                  })}
                              >
                                  <Form onSubmit={addChip}>
                                      <Input
                                          disabled={!hasProjectBeenSelected}
                                          className={classNames(styles.InputWrapper, {
                                              [styles.ActiveFilterSearchInput]:
                                              (searchParameters.searchText ||
                                                  searchParameters.chipList.length) &&
                                              expandedSearchBar,
                                          })}
                                          size="small"
                                          actionPosition="left"
                                          ref={searchInputRef}
                                          action={{
                                              icon: <FontAwesomeIcon icon={faSearch}/>,
                                              loading: searchBarLoading,
                                              disabled: !hasProjectBeenSelected,
                                              className: classNames({
                                                  [styles.ActiveFilterSearchButton]:
                                                  searchParameters.searchText ||
                                                  searchParameters.chipList.length,
                                              }),
                                          }}
                                          placeholder="Search..."
                                          onChange={async (evt) => {
                                              dispatch(SearchActions.setSearchBarLoading(true));
                                              await handleChangeSearchValue(evt.target.value);
                                          }}
                                      />
                                  </Form>
                                  <div className={styles.CalendarSearch}
                                       onClick={() => setDateFilterOpen(prevState => !prevState)}>
                                      <FontAwesomeIcon
                                          className={styles.CalendarSearchButton}
                                          icon={faCalendar}/>
                                  </div>
                              </div>
                              From:
                              <div style={{marginRight: "10px", zIndex: 300}}>
                                  <DatePicker enableTabLoop={false}
                                              dateFormat="dd-MMM-yyyy"
                                              selected={searchParameters.startDate ? new Date(searchParameters.startDate) : null}
                                              onChange={(date) => dispatch(searchActions.SearchActions.setStartDate(date ? date.getTime() : null))}
                                  />
                              </div>
                              To:
                              <div style={{marginRight: "10px", zIndex: 300}}>
                                  <DatePicker enableTabLoop={false}
                                              dateFormat="dd-MMM-yyyy"
                                              selected={searchParameters.finishDate ? new Date(searchParameters.finishDate) : null}
                                              onChange={(date) => dispatch(searchActions.SearchActions.setFinishDate(date ? date.getTime() : null))}
                                  />
                              </div>
                          </div>}
                      <ChipsList/>
                      <OnlineUsers/>
                  </div>
                  <div className={styles.View}>
                      {successorsView || predecessorsView ? (
                          <Button
                              className={styles.BtnCloseView}
                              onClick={() => closeView(view)}
                          >
                              {view.substring(0, 1).toUpperCase() +
                                  view.substring(1, view.length)}
                              <span style={{position: "relative"}}>
                  <FontAwesomeIcon icon={faTimes}/>
                </span>
                          </Button>
                      ) : (
                          <div className={styles.DaysBanked}></div>
                      )}
                  </div>
              </div>
              <div className={styles.InteractionBar}>
              {gannt && <Button circular size="tiny"
                                id={"printGantt"}
                      // disabled={!activeProject || !activeProject.projectId || quality}
                            disabled={true}
                          active={props.isOpenedCalendar}
                          // className={classNames({
                          //     [styles.ButtonCalendar]: gannt,
                          // })}
                          onClick={() => {
                              setHoverButton(null)
                              setGannt(!gannt)
                              dispatch(projectActions.Actions.setView(gannt ? "task" : "gantt"))
                          }}
                            onMouseEnter={() => handleHoverButton("Print Gantt chart")}
                            onMouseLeave={() => setHoverButton(null)}
              >
                      <FontAwesomeIcon icon={faPrint}/>
                  </Button>}
                  <Button circular size="tiny"
                          id={"gantt"}
                          disabled={!activeProject || !activeProject.projectId || quality}
                          active={props.isOpenedCalendar}
                          className={classNames({
                              [styles.BetaButton]: true,
                              [styles.ButtonCalendar]: gannt
                          })}
                          onClick={() => {
                              setHoverButton(null)
                              setGannt(!gannt)
                              dispatch(projectActions.Actions.setView(gannt ? "task" : "gantt"))
                          }}
                          onMouseEnter={() => handleHoverButton("Gantt view")}
                          onMouseLeave={() => setHoverButton(null)}
                  >
                      <span className={styles.BetaContainer}><p className={styles.BetaTag}>BETA</p></span>
                      <FontAwesomeIcon icon={faChartGantt}/>
                  </Button>
                  <Button circular size="tiny"
                          id={"sprint"}
                          disabled={!activeProject || !activeProject.projectId || !activeProject.lastSnapshotTimestamp}
                          active={props.isOpenedCalendar}
                          className={classNames({
                              [styles.BetaButton]: true,
                              [styles.ButtonCalendar]: globalView === "sprint",
                          })}
                          onClick={() => {
                              setHoverButton(null)
                              dispatch(projectActions.Actions.setView(projectView === "sprint" ? "task" : "sprint"))
                          }}
                          onMouseEnter={() => handleHoverButton("Sprint view")}
                          onMouseLeave={() => setHoverButton(null)}
                  >
                      <span className={styles.BetaContainer}><p className={styles.BetaTag}>BETA</p></span>
                      <FontAwesomeIcon icon={faPersonRunningFast}/>
                  </Button>
                  <Button circular size="tiny"
                          id={"change"}
                          // disabled={!activeProject || !activeProject.projectId || !activeProject.lastSnapshotTimestamp}
                          disabled={true}
                          active={props.isOpenedCalendar}
                          className={classNames({
                              [styles.BetaButton]: true,
                              [styles.ButtonCalendar]: globalView === "change",
                          })}
                          onClick={() => {
                              setHoverButton(null)
                              dispatch(projectActions.Actions.setView(projectView === "change" ? "task" : "change"))
                          }}
                          onMouseEnter={() => handleHoverButton("Change log")}
                          onMouseLeave={() => setHoverButton(null)}
                  >
                      <span className={styles.BetaContainer}><p className={styles.BetaTag}>BETA</p></span>
                      <FontAwesomeIcon icon={faReflectHorizontal}/>
                  </Button>
                  <Button circular size="tiny"
                          id={"map-button"}
                          disabled={!activeProject || !activeProject.projectId || quality}
                          active={props.isOpenedCalendar}
                          className={classNames({
                              [styles.BetaButton]: true,
                              [styles.ButtonCalendar]: map,
                          })}
                          onClick={() => {
                              setHoverButton(null)
                              setMap(!map)
                              dispatch(projectActions.Actions.setView(map ? "task" : "task-map"))
                          }}
                          onMouseEnter={() => handleHoverButton("Open map")}
                          onMouseLeave={() => setHoverButton(null)}
                  >
                      <span className={styles.BetaContainer}><p className={styles.BetaTag}>BETA</p></span>
                      <FontAwesomeIcon icon={faMapLocationDot}/>
                  </Button>
                  <Button circular size="tiny"
                          id={"quality"}
                          disabled={!activeProject || !activeProject.projectId}
                          active={props.isOpenedCalendar}
                          className={classNames({
                              [styles.BetaButton]: true,
                              [styles.ButtonCalendar]: quality,
                          })}
                          onClick={() => {
                              setHoverButton(null)
                              setQuality(!quality)
                              if (map) {
                                  setMap(false)
                              }
                              dispatch(projectActions.Actions.setView(quality ? "task" : "quality"))
                          }}
                          onMouseEnter={() => handleHoverButton("Quality view")}
                          onMouseLeave={() => setHoverButton(null)}
                  >
                      <span className={styles.BetaContainer}><p className={styles.BetaTag}>BETA</p></span>
                      <FontAwesomeIcon icon={faChartPie}/>
                  </Button>
                  <Button
                      id={"calendar"}
                      disabled={!Boolean(activeProject)}
                      className={classNames({
                          [styles.ButtonCalendar]: props.isOpenedCalendar,
                      })}
                      onClick={() => {
                          setHoverButton(null)
                          props.openSpecificAdditional("calendar", !props.isOpenedCalendar)
                      }}
                      onMouseEnter={() => handleHoverButton("Task calendar")}
                      onMouseLeave={() => setHoverButton(null)}
                      active={props.isOpenedCalendar}
                  >
                      <FontAwesomeIcon icon={faCalendar}/>
                  </Button>
                  <Button
                      id={"ledger"}
                      disabled={!Boolean(activeProject)}
                      className={
                          activeTask
                              ? classNames({
                                  [styles.ButtonLedgerActive]: props.isOpenedLedger,
                              })
                              : classNames({[styles.ButtonLedger]: props.isOpenedLedger})
                      }
                      onClick={() => {
                          setHoverButton(null)
                          props.openSpecificAdditional("ledger", !props.isOpenedLedger);
                          updateUserTaskSeenHandler();
                      }}
                      onMouseEnter={() => handleHoverButton("Task ledger")}
                      onMouseLeave={() => setHoverButton(null)}
                      active={props.isOpenedLedger}
                  >
                      <FontAwesomeIcon icon={faCommentsAlt}/>
                  </Button>
                  <Button
                      id={"member"}
                      disabled={!Boolean(activeProject)}
                      className={classNames({
                          [styles.ButtonMember]: props.isOpenedMember,
                      })}
                      onClick={() => {
                          setHoverButton(null)
                          props.openSpecificAdditional("member", !props.isOpenedMember)
                      }}
                      onMouseEnter={() => handleHoverButton("Project team")}
                      onMouseLeave={() => setHoverButton(null)}
                      active={props.isOpenedMember}
                  >
                      <FontAwesomeIcon icon={faUsers}/>
                  </Button>
                  {/*<Button*/}
                  {/*    disabled={!Boolean(activeProject)}*/}
                  {/*    className={classNames({*/}
                  {/*        [styles.ButtonMember]: props.isOpenedMember,*/}
                  {/*    })}*/}
                  {/*    onClick={() =>*/}
                  {/*        fetch('https://europe-west3-flowledgerprod.cloudfunctions.net/create-chat-context/?projectId=SmVKcpBaGZqhtM1Kc9Rh',*/}
                  {/*              {*/}
                  {/*                  method: 'POST',*/}
                  {/*                  mode: 'no-cors'*/}
                  {/*              })*/}
                  {/*            .then(() => console.log())*/}
                  {/*}*/}
                  {/*    active={props.isOpenedMember}*/}
                  {/*>*/}
                  {/*    <FontAwesomeIcon icon={faUsers}/>*/}
                  {/*</Button>*/}
              </div>
              {hoverButton && !(expandedSearchBar && hoverButton.text === 'Search tasks') && <div
                  className={styles.HoverTooltip}
                  style={{top: hoverButton.y, left: hoverButton.x}}
              >{hoverButton.text}</div>}
          </div>
          <div className={styles.BreadcrumbBar}>
        <span className="flowledger-header-subtitle" style={newWbsAddress === "WBS Path:" ? {
            color: "grey"} : {
            maxWidth: "100vw",
            overflow: "hidden",
            maxHeight: "20px",
            textOverflow: "ellipsis",
        }}>
          {activeProject ? `${newWbsAddress}` : ``}
        </span>
          </div>
          {newTaskModalOpen &&
              <NewTaskModal props={{setNewTaskModalOpen, newTaskModalOpen, projectId: activeProject!.projectId}}/>}
      </>
  );
};
